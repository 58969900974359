import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import { LibraryEditValidation } from "../../Validations/LibraryValidation/LibraryEditValidation";
import {
  Button,
  Form,
  Dropdown,
  DropdownButton,
  Col,
  Row,
} from "react-bootstrap";
import {
  postFormData,
  postData,
  getData,
  deleteFormData,
} from "../../../axios/apiHelper";
import { loader } from "../../../loader";
import { ENDPOINT } from "../../../axios/apiConfig";
import CommonModel from "../../../Model/CommonModel";
import moment from "moment";
import optimizeImage from "../../../Utils/optimizeImage";
let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED_DESIGN;

const EditLibrary = () => {
  const { state } = useLocation();
  const titleFieldRef = useRef(null);
  const limitFieldRef = useRef(null);
  const [counterFlag, setCounterFlag] = useState(0);
  const [show, setShow] = useState(false);
  const [allTags, setAllTags] = useState({});
  const [newTag, setNewTag] = useState("");
  const [commanShow, setCommanShow] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [tagClickedFirst, setTagClickedFirst] = useState([]);
  const [spcType, setSpcType] = useState(0);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [finalTags, setFinalTags] = useState([]);
  const [tagsReRender, setTagsReRender] = useState(0);
  const [hcpIrtClickedFirst, setHcpIrtClickedFirst] = useState([]);
  const [mandatoryRole, setMandatoryRole] = useState([
    "Site User-Blinded",
    "Investigator-Blinded",
    "Site unblinded pharmacist",
  ]);
  const location = useLocation();
  // console.log(location,'locationhtyh')
  const [updateflag, setupdateFlag] = useState(0);
  const [ebookVideoType, setEbookVideoType] = useState([
    {
      index: "",
      type: "",
    },
  ]);
  const [userInputs, setCreateLibraryInputs] = useState({
    expDatetime: "",
    keyAuthor: "",
    limit: "",
    uploadFile: "",
    contentTitle: "",
    allow_share: "",
    allow_download: "",
    allow_print: "",
    country: "",
    company: "",
    journalTitle: "",
    docintelFormat: "",
    product: "",
    coverPhoto: "",
    productionNotes: "",
    specialRequirment: "",
    category: "",
    language: "",
    format: "",
    ibu: "",
    allow_oneSource: "",
    allow_draft: "",
    chat_box: "",
    allow_video: "",
    comDatetime: "",
    cpdValue: "",
    sold_unsold_status: "",
    request_quote: '',
    pharmaArr: '',
    parent_id:""
  });

  const [getVideoArticle, setVideoArticle] = useState([]);

  const [language, setLanguage] = useState([
    { value: "English", label: "English" },
    { value: "Russian", label: "Russian" },
  ]);

  const [blindType, setBlindType] = useState([
    { value: "blinded", label: "blind" },
    { value: "unblinded", label: "unblind" },
  ]);
  const [ebookFile, setEbookFile] = useState([]);
  const [videoThumb, setVideoThumb] = useState([]);
  const [libraryData, setLibraryData] = useState([]);

  const [chapter, setChapter] = useState([
    {
      chapterTitle: "",
      uploadFile: "",
      fileValue: "",
      selectedVideo: "",
    },
  ]);
  const [pdfSpcChapter, setPdfSpcChapter] = useState([
    {
      chapterTitle: "",
      uploadFile: "",
      fileValue: "",
    },
  ]);
  const [reseller, setReseller] = useState([]);
  const [userDetail, setUserDetail] = useState({
    user: {},
    production: [],
    pharmaArr: [],
    sales: [],
    country: [],
    format: [],
    product: [],
    costCenter: [],
    hcp: [
      "Principal Investigator",
      "Sub-Investigator",
      "Study Coordinator",
      "Study Nurse",
      "Other",
    ],
    trial: [{ label: "LEXx210", value: "3972" }],
  });
  const [id, setId] = useState(localStorage.getItem("user_id"));

  const product = [
    {
      label: "Product name",
      type: "input",
      placeholder: "Type your product name",
    },
  ];

  // const [ePrintType, setePrintType] = useState([]);

  const [ePrintType, setePrintType] = useState([
    { value: "pdf", label: "PDF" },
    { value: "video", label: "video" },
    { value: "ebook", label: "eBook" },
  ]);
  const [pdfSpcData, setpdfSpcData] = useState([
    {
      chapterTitle: "",
      uploadFile: "",
      fileValue: "",
    },
  ]);

  const [tagsCounter, setTagsCounter] = useState(0);
  const [chapterSelect, setChapterSelect] = useState("");
  const [videoSelect, setVideoSelect] = useState("");
  const [uploadNewVideo, setUploadNewVideo] = useState(false);
  const [changeEmbeddedVideo, setChangeEmbeddedVideo] = useState("");
  const [showFlag, setShowFlag] = useState(false);
  const [hcpClickedFirst, setHcpClickedFirst] = useState([]);
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const initalFun = async () => {
    try {
      loader("show");
      const hadData = await postData(ENDPOINT.LIBRARYDETAIL, {
        user_id: id,
        irt: location?.state?.flag == "mandatory" ? 1 : 0,
      });

      if (hadData?.data?.data?.fileType) {
        setePrintType(hadData?.data?.data?.fileType);
      }

      let country = [];
      if (hadData?.data?.data?.country?.length) {
        if (typeof hadData?.data?.data?.country == "string") {
          JSON.parse(hadData?.data?.data?.country)?.reduce(
            (objEntries, key) => {
              country.push({
                label: key,
                value: key,
              });
            }
          );
        } else {
          hadData?.data?.data?.country?.reduce((objEntries, key) => {
            country.push({
              label: key,
              value: key,
            });
          });
        }
      }

      setSpcType(hadData?.data?.data?.spcInc);

      let category = [];
      if (hadData?.data?.data?.category?.length) {
        hadData?.data?.data?.category.reduce((objEntries, key) => {
          category.push({
            label: key,
            value: key,
          });
        });
      }

      let tags = [];
      if (hadData?.data?.data?.tags?.length) {
        hadData?.data?.data?.tags?.forEach((item) => {
          tags.push(item?.value);
        });
      }

      setAllTags(tags);

      setUserDetail({
        ...userDetail,
        user: hadData?.data?.data?.user,
        production: hadData?.data?.data?.production,
        pharmaArr: hadData?.data?.data?.pharma_arr,
        country: country,
        costCenter: hadData?.data?.data?.costCenter,
        sales: hadData?.data?.data?.sale,
        format: hadData?.data?.data?.format,
        category: category,
        ibu: hadData?.data?.data?.ibu,
        product: hadData?.data?.data?.product,
        reseller: hadData?.data?.data?.reseller,
      });
    } catch (err) {
      console.log(err);
    } finally {
      loader("hide");
    }
  };
  const libraryDetail = async () => {
    try {
      loader("show");
      const hadData = await getData(
        `${ENDPOINT.LIBRARY_DETAIL_BY_ID}/${state?.pdfid}`
      );
      setCreateLibraryInputs(hadData?.data?.data?.pdfData);
      if (
        hadData?.data?.data?.pdfData?.tags?.length &&
        isJsonString(hadData?.data?.data?.pdfData?.tags)
      ) {
        setTagClickedFirst(JSON.parse(hadData?.data?.data?.pdfData?.tags));
        setFinalTags(JSON.parse(hadData?.data?.data?.pdfData?.tags));
      }
      if (hadData?.data?.data?.pdfData?.trail_user_type?.length) {
        if (hadData?.data?.data?.pdfData?.reader_mandatory == 1) {
          setHcpIrtClickedFirst(
            JSON.parse(hadData?.data?.data?.pdfData?.trail_user_type)
          );
        } else {
          setHcpClickedFirst(
            JSON.parse(hadData?.data?.data?.pdfData?.trail_user_type)
          );
        }
      }
      setReseller(
        hadData?.data?.data?.pdfData?.multiple_publisher &&
          isJsonString(hadData?.data?.data?.pdfData?.multiple_publisher)
          ? JSON.parse(hadData?.data?.data?.pdfData?.multiple_publisher)
          : []
      );
      if (hadData?.data?.data?.pdfData?.docintelFormat == "pdfSpc") {
        setpdfSpcData(hadData?.data?.data?.ebookData);
      } else if (hadData?.data?.data?.ebookData?.length) {
        setChapter(hadData?.data?.data?.ebookData);
      }
      if (hadData?.data?.data?.pdfData?.docintelFormat == "ebookVideo") {
        setChapter(hadData?.data?.data?.ebookData);
        setpdfSpcData(hadData?.data?.data?.ebookData);
      } 
      setShowFlag(true);
    } catch (err) {
      console.log("-err", err);
    } finally {
      loader("hide");
    }
  };
  useEffect(() => {
    libraryDetail();
    initalFun();
    getExistingVideos();
    
  }, []);
const getExistingVideos=async ()=>{
  const requestBody = {
    selectValue: JSON.stringify(["id", "title", "code"]),
    file_type: "'video'",
    is_file_name_exists: 1,
  };
  const response = await postData(ENDPOINT.LIBRARY, requestBody);
  // const response = await postData(ENDPOINT.LIBRARY_CONTENT, requestBody);
  const hadData = response?.data?.data?.library || [];
  const pdfObj = hadData
    .map((item) => ({
      label: item.title.trim(),
      value: item.id,
    }))
    .sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );
  setVideoArticle(pdfObj);
}
  const removeHcp = (data, type = "") => {
    if (type == "irt") {
      const hcpData = hcpIrtClickedFirst.filter((item) => item != data);
      setHcpIrtClickedFirst(hcpData);
    } else {
      const hcpData = hcpClickedFirst.filter((item) => item != data);
      setHcpClickedFirst(hcpData);
    }
  };

  const hcpClicked = (dd) => {
    if (!hcpClickedFirst.includes(dd)) {
      if (dd == "All") {
        setHcpClickedFirst(userDetail?.hcp);
      } else {
        setHcpClickedFirst((oldArray) => [...oldArray, dd]);
      }
    } else {
      toast.error("Role already Selected.");
    }
  };

  const hcpIrtClicked = (dd) => {
    if (!hcpIrtClickedFirst.includes(dd)) {
      if (dd == "All") {
        setHcpIrtClickedFirst(mandatoryRole);
      } else {
        const newArray = [dd];
        setHcpIrtClickedFirst(newArray);
      }
    } else {
      toast.error("Role already Selected.");
    }
  };

  const newTagChanged = (e) => {
    setNewTag(e.target.value);
    e.target.value = "";
    const new_atg = document.getElementById("new-tag");
    new_atg.value = "";
  };

  const tagClicked = (dd) => {
    if (!tagClickedFirst.includes(dd)) {
      setTagClickedFirst((oldArray) => [...oldArray, dd]);
    } else {
      toast.error("Tag already in list.");
    }
  };

  const onPdfTitleChange = (e, i) => {
    const { value } = e.target;
    const list = [...pdfSpcData];
    list[i].chapterTitle = value;
    setpdfSpcData(list);
  };

  const handleOnEbookPdfChange = (e, i) => {
    const value = e.target.files[0]?.name;
    const list = [...pdfSpcData];
    list[i].uploadFile = value;
    ebookFile[i] = e.target.files[0];
    setEbookFile(ebookFile);
    setpdfSpcData(list);
  };

  const addTag = async () => {
    if (typeof newTag == "undefined" || newTag.trim().length == 0) {
      toast.error("Please input a tag");
    } else {
      loader("show");
      try {
        await postData(ENDPOINT.ADD_TAGS, {
          product: newTag,
          type: 2,
          irt: location?.state?.flag == "mandatory" ? 1 : 0,
        });
        loader("hide");
        let temp_tags = tagClickedFirst.map((data) => {
          return data.toLowerCase();
        });
        let alltemp_tags = [];
        Object.entries(allTags).map((data) => {
          return alltemp_tags.push(...data);
        });
        alltemp_tags = alltemp_tags.map((data) => {
          return data.toLowerCase();
        });

        if (
          !temp_tags.includes(newTag.toLowerCase()) &&
          !alltemp_tags.includes(newTag.toLowerCase())
        ) {
          setTagClickedFirst((oldArray) => [...oldArray, newTag]);

          const body = {
            user_id: localStorage.getItem("user_id"),
            tags: newTag,
          };
        } else {
          toast.error("Tag already in list.");
        }
        setNewTag("");
        setTagsCounter(tagsCounter + 1);
      } catch (err) {
        loader("hide");
        console.log(err);
      }
    }
  };
  const handleChange = async (e, isSelectedName) => {
    if (e?.target?.files?.length < 1) {
      return;
    }
    if (isSelectedName == "docintelFormat") {
      if (e == "ebook" || e == "ebookVideo") {
        setEbookFile([]);
        setpdfSpcData([
          {
            chapterTitle: "",
            uploadFile: "",
            fileValue: "",
          },
        ]);
      } else if (e == "pdfSpc") {
        setEbookFile([]);
        setChapter([
          {
            chapterTitle: "",
            uploadFile: "",
            fileValue: "",
            selectedVideo: "",
          },
        ]);
      }
      setCreateLibraryInputs({
        ...userInputs,
        uploadFile: "",
        [isSelectedName ? isSelectedName : e?.target?.name]: isSelectedName
          ? e?.target?.files
            ? e?.target?.files
            : e
          : e?.target?.value,
        allow_video: 0,
      });
    } else {
      if (isSelectedName == "coverPhoto" ) {
        const file = e.target.files[0]
        let optimizedFile = await optimizeImage(file,{width:125})
        setCreateLibraryInputs({
          ...userInputs,
          ["coverPhoto"]: [optimizedFile]
        });

      }else{
        setCreateLibraryInputs({
          ...userInputs,
          [isSelectedName ? isSelectedName : e?.target?.name]: isSelectedName
            ? e?.target?.files
              ? e?.target?.files
              : e
            : e?.target?.value,
        });
      }
 
    }
  };

  const nextButtonClicked = async (e) => {
    e.preventDefault();

    if (userInputs.docintelFormat == "ebook" || userInputs.docintelFormat == "ebookVideo") {
      userInputs.chapter = chapter;
    } else if (userInputs.docintelFormat == "pdfSpc") {
      userInputs.pdfChapter = pdfSpcData;
    }
    if (
      userDetail?.user?.[0]?.flag == 1 &&
      userDetail?.user?.[0]?.group_id == 3
    ) {
      if (!userInputs?.trial) {
        userInputs.trial = "";
      }
      if (!userInputs?.blindType) {
        userInputs.blindType = "";
      }
    } else {
      delete userInputs.blindType;
      delete userInputs.trial;
    }
    const err = LibraryEditValidation(userInputs);
    if (Object.keys(err)?.length) {
      if (Object.keys(err)[0] == "limit") {
        limitFieldRef.current.focus();
      } else if (Object.keys(err)[0] == "contentTitle") {
        titleFieldRef.current.focus();
      }
      toast.error(err[Object.keys(err)[0]]);
      setError(err);
      return;
    } else {
      try {
        loader("show");
        let formData = new FormData();
        formData.append("keyAuthor", userInputs?.keyAuthor);
        formData.append(
          "production",
          userInputs?.production_id ? userInputs?.production_id : 0
        );
        formData.append(
          "sales",
          userInputs?.sales_id ? userInputs?.sales_id : 0
        );
        formData.append(
          "costCenter",
          userInputs?.cost_center ? userInputs?.cost_center : ""
        );
        formData.append("expDatetime", userInputs?.expDatetime);
        formData.append("limit", userInputs?.limit);
        formData.append("file", userInputs?.uploadFile?.[0]);
        formData.append("title", userInputs?.contentTitle);

        if (localStorage.getItem("user_id") == "B7SHpAc XDXSH NXkN0rdQ==") {
          formData.append("language", userInputs?.language);
        }

        formData.append("allowShare", JSON.stringify(userInputs?.allow_share));
        if (
          userDetail?.user?.[0]?.group_id == 3 &&
          userDetail?.user?.[0]?.flag == 1
        ) {
          formData.append("blindType", userInputs?.blindType);
          formData.append("trial", userInputs?.trial);
          formData.append(
            "mandatory",
            userInputs?.reader_mandatory
              ? JSON.stringify(userInputs?.reader_mandatory)
              : JSON.stringify(false)
          );
          userInputs?.reader_mandatory
            ? formData.append(
                "trail_user_type",
                hcpIrtClickedFirst?.length
                  ? JSON.stringify(hcpIrtClickedFirst)
                  : ""
              )
            : formData.append(
                "trail_user_type",
                hcpClickedFirst?.length ? JSON.stringify(hcpClickedFirst) : ""
              );
        }
        if (userDetail?.user?.[0]?.group_id == 3 &&
          userDetail?.user?.[0]?.octaLach == 1
        ) {
          formData.append(
            "medical",
            userInputs?.medical
              ? JSON.stringify(userInputs?.medical)
              : JSON.stringify(false)
          );

          formData.append(
            "sync_onesource",
            userInputs?.sync_onesource
              ? JSON.stringify(userInputs?.sync_onesource)
              : JSON.stringify(false)
          );
        }
        formData.append(
          "multiplePublisher",
          reseller?.length ? JSON.stringify(reseller) : ""
        );
        formData.append(
          "allowDownload",
          JSON.stringify(userInputs?.allow_download)
        );
        formData.append("allowPrint", JSON.stringify(userInputs?.allow_print));
        formData.append("pdfId", state?.pdfid);
        formData.append("country", userInputs?.country);
        formData.append("company", userInputs?.company);
        formData.append("journalTitle", userInputs?.journalTitle);
        formData.append(
          "fileType",
          userInputs?.docintelFormat == "pdfSpc"
            ? "ebook"
            : userInputs?.docintelFormat
        );
        if (userInputs?.docintelFormat == "pdfSpc") {
          formData.append("spcInc", spcType);
        } else {
          formData.append("spcInc", 0);
        }
        formData.append("product", userInputs?.product);
        ebookFile?.forEach((item) => {
          formData.append("ebookData", item);
        });
        videoThumb?.forEach((item) => {
          formData.append("videoThumb", item);
        });
        formData.append("coverPhoto", userInputs?.coverPhoto?.[0]);
        formData.append(
          "chapter",
          userInputs?.docintelFormat == "pdfSpc"
            ? JSON.stringify(pdfSpcData)
            : JSON.stringify(chapter)
        );
        formData.append("productionNotes", userInputs?.productionNotes);
        formData.append("specialRequirment", userInputs?.specialRequirment);
        formData.append("createdBy", id);
        formData.append("category", userInputs?.category);
        formData.append("format", userInputs?.format);
        formData.append("ibu", userInputs?.ibu);
        formData.append(
          "allowOneSource",
          JSON.stringify(userInputs?.allow_oneSource)
        );
        formData.append("allowLibrary", userInputs?.allowLibrary);
        formData.append("allowRequest", JSON.stringify(userInputs?.chat_box));
        formData.append("draft", JSON.stringify(userInputs?.draft));

        let allowVideoValue = 0;
        if (userInputs?.docintelFormat == "video") {
          formData.append("allowVideo", 0);
        } else {
          allowVideoValue = userInputs?.allow_video ? 1 : 0;
          formData.append(
            "allowVideo",
            JSON.stringify(userInputs?.allow_video)
          );
        }

        formData.append("comDatetime", userInputs?.comDatetime);
        formData.append("cpdValue", userInputs?.cpdValue);
        formData.append(
          "tags",
          tagClickedFirst?.length ? JSON.stringify(tagClickedFirst) : ""
        );
        if(userDetail?.user?.[0]?.retailer == 1){
          formData.append("request_quote", userInputs?.request_quote ? 1 : 0);
          formData.append("sold_unsold_status", userInputs?.sold_unsold_status);
          formData.append(
            "pharama_val",
            userInputs?.pharmaArr ? userInputs?.pharmaArr : ""
          );
        }
        await postFormData(ENDPOINT.UPDATE_ARTICLE, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "CustomHeader": allowVideoValue
          },
        });
        loader("hide");
        if (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") {
          if (
            userInputs?.docintelFormat == "video" ||
            userInputs?.docintelFormat == "Video"
          ) {
            navigate("/content-detail", {
              // state: { pdfId: state?.pdfid },
              state: { pdfId: state?.pdfid , 
                // title : location?.state?.title,
                title: localStorage.getItem("user_id") === "56Ek4feL/1A8mZgIKQWEqg==" ||  localStorage.getItem("user_id") ==="sNl1hra39QmFk9HwvXETJA==" ? location?.state?.title : '',
                flag: localStorage.getItem("user_id") ==="56Ek4feL/1A8mZgIKQWEqg==" ||  localStorage.getItem("user_id") ==="sNl1hra39QmFk9HwvXETJA=="
                ? (location?.state?.flag === "Non-mandatory" ? 'Non-mandatory' : "mandatory")
                : ''},
            });
          }else {
            if (userInputs?.allow_video) {
              navigate("/library-add-link", {
                state: {
                  pdfId: state?.pdfid,
                  isEdit: 1,
                  allowVideo: userInputs?.allow_video ? true : false,
                  flag : localStorage.getItem("user_id") ==="56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") ==="sNl1hra39QmFk9HwvXETJA=="  ?(location?.state?.flag === "mandatory"
                    ? "mandatory"
                    : location?.state?.flag === "Non-mandatory"
                    ? "Non-mandatory" : '') :'',
                    title: localStorage.getItem("user_id") ==="56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") ==="sNl1hra39QmFk9HwvXETJA=="
                    ? (location?.state?.title)
                    : ''
                },
              });
            }else{
              navigate("/preview-content", {
                state: { pdfId: state?.pdfid, isEdit: 1, 
                  // title : location?.state?.title,
                  title: localStorage.getItem("user_id") === "56Ek4feL/1A8mZgIKQWEqg=="||  localStorage.getItem("user_id") ==="sNl1hra39QmFk9HwvXETJA==" ? location?.state?.title : '',
                  flag: localStorage.getItem("user_id") ==="56Ek4feL/1A8mZgIKQWEqg==" ||  localStorage.getItem("user_id") ==="sNl1hra39QmFk9HwvXETJA=="
                  ? (location?.state?.flag === "Non-mandatory" ? 'Non-mandatory' : "mandatory")
                  : ''}
              });
            }
          }
        } else {
          if (localStorage.getItem("user_id") == "rjiGlqA9DXJVH7bDDTX0Lg==") {
            if (
              userInputs?.docintelFormat == "video" ||
              userInputs?.docintelFormat == "Video"
            ) {
              navigate("/set-popup", {
                state: {
                  pdfId: state?.pdfid,
                  fileType: userInputs?.docintelFormat,
                  isEdit: 1,
                },
              });
            } else {
              if (userInputs?.allow_video) {
                navigate("/library-add-link", {
                  state: {
                    pdfId: state?.pdfid,
                    isEdit: 1,
                    allowVideo: userInputs?.allow_video ? true : false,
                  },
                });
              } else {
                navigate("/set-popup", {
                  state: {
                    pdfId: state?.pdfid,
                    fileType: userInputs?.docintelFormat,
                    isEdit: 1,
                  },
                });
              }
            }
          } else {
            if (
              userInputs?.docintelFormat == "video" ||
              userInputs?.docintelFormat == "Video"
            ) {
              navigate("/set-popup", {
                state: {
                  pdfId: state?.pdfid,
                  fileType: userInputs?.docintelFormat,
                  ibu:userInputs?.ibu?userInputs?.ibu:"",
                  lng:userInputs?.language?userInputs?.language:10,
                  isEdit: 1,
                },
              });
            } else {
              if (userInputs?.allow_video) {
                navigate("/library-add-link", {
                  state: {
                    pdfId: state?.pdfid,
                    isEdit: 1,
                    allowVideo: userInputs?.allow_video ? true : false,
                  },
                });
              } else {
                navigate("/set-popup", {
                  state: {
                    pdfId: state?.pdfid,
                    fileType: userInputs?.docintelFormat,
                    ibu:userInputs?.ibu?userInputs?.ibu:"",
                    lng:userInputs?.language?userInputs?.language:10,
                    isEdit: 1,
                  },
                });
              }
            }
            // navigate("/set-popup", {
            //   state: {
            //     pdfId: state?.pdfid,
            //     fileType: userInputs?.docintelFormat,
            //     isEdit: 1,
            //   },
            // });
          }
        }
      } catch (err) {
        loader("hide");
        console.log(err);
      }
    }
  };

  // const addMoreChClicked = () => {
  //   if (chapter.every((element) => element.uploadFile != "")) {
  //     setChapter([
  //       ...chapter,
  //       {
  //         chapterTitle: "",
  //         uploadFile: "",
  //       },
  //     ]);
  //   } else {
  //     toast.warning("Please input the chapter file atleast!");
  //   }
  // };

  const onChapterFormatChange = (e, i, isSelectedName) => {
    const list = [...chapter];  
    list[i].type= e == true ? "video" : "pdf";
    list[i].uploadFile=""; 
    list[i].selectedVideo = ""; 
    list[i].ebookFile="" 
    list[i].videoType=""
    list[i].videoThumb=""
    if(ebookFile[i]){
      setEbookFile((prevEbookFile) => {
        const updatedEbookFile = [...prevEbookFile];
        updatedEbookFile.splice(i, 1);
        if (updatedEbookFile.length === 0) {
          return [];
        }
        return updatedEbookFile;
      });
    }
    if(e==true){      
      list[i].videoType="existing"
      onSelectVideoType(e,i,"existing")
    }     
    setChapter(list);   
  };

  // const addMoreChClicked = () => {
  //   let isValid = true;
  //   let toastMessage = '';
  
  //   chapter.forEach((element) => {
  //     const isVideoExisting = element.type === 'video' && element.videoType === 'existing';
  //     const selectedVideoEmpty = !element.selectedVideo || element.selectedVideo === "";
  //     const uploadFileEmpty = !element.uploadFile || element.uploadFile === "";
      
  //     if (isVideoExisting && selectedVideoEmpty && userInputs.docintelFormat == "ebookVideo") {
  //       isValid = false;
  //       toastMessage = "Please select a video!";
  //     } else if (!isVideoExisting && uploadFileEmpty) {
  //       isValid = false;
  //       toastMessage = "Please input the chapter file atleast!";
  //     }
  //   });
  
  //   if (isValid) {
  //     setChapter([
  //       ...chapter,
  //       {
  //         chapterTitle: "",
  //         uploadFile: "",
  //         selectedVideo: ""
  //       },
  //     ]);
  //   } else {
  //     toast.warning(toastMessage);
  //   }
  // };

  const addMoreChClicked = () => {
    let isValid = true;
    let toastMessage = '';
    chapter.forEach((element) => {
      const isVideoExisting = element.type === 'video' && element.videoType === 'existing';
      const selectedVideoEmpty = !element.selectedVideo || element.selectedVideo === "";
      const uploadFileEmpty = !element.uploadFile || element.uploadFile === "";
      const uploadChapterId = !element.id || element.id === "";
      if (isVideoExisting && selectedVideoEmpty && userInputs.docintelFormat == "ebookVideo") {
        isValid = false;
        toastMessage = "Please select a video!";
      } else if (!isVideoExisting && (uploadFileEmpty && uploadChapterId)) {
        isValid = false;
        toastMessage = "Please input the chapter file atleast!";
      }
    });

    if (isValid) {
      setChapter([
        ...chapter, userInputs.docintelFormat == "ebookVideo" ?
          {
            // chapterFormat: "pdf",
            type:"pdf",
            chapterTitle: "",
            uploadFile: "",
            selectedVideo: ""
          } : {
            chapterTitle: "",
            uploadFile: "",
            selectedVideo: ""
          },
      ]);
    } else {
      toast.warning(toastMessage);
    }
  };

  const handleReseller = (e, data) => {
    let newData = [];
    if (e.target.checked) {
      newData = reseller;
      newData.push(data?.id);
    } else {
      newData = reseller?.filter((item) => item != data?.id);
    }
    setReseller(newData);
  };

  const deleteRecord = async (i, id) => {
    if (id) {
      try {
        loader("show");
        await deleteFormData(`${ENDPOINT.DELETE_PDF_FILE}/${id}`);
      } catch (err) {
        console.log(err);
      } finally {
        loader("hide");
      }
    }
    const list = chapter;
    list.splice(i, 1);
    setChapter(list);
    setCounterFlag(counterFlag + 1);
  };

  const onChapterTitleChange = (e, i) => {
    const { value } = e.target;
    const list = [...chapter];
    list[i].chapterTitle = value;
    setChapter(list);
  };

  const removeTagFinal = (index, status = "") => {
    if (status == "") {
      const tags = finalTags;
      tags.splice(index, 1);
      setFinalTags(tags);
    }
    const tagsClickedFirst = tagClickedFirst;
    tagsClickedFirst.splice(index, 1);
    setTagClickedFirst(tagsClickedFirst);

    setTagsReRender(tagsReRender + 1);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const saveButtonClicked = async () => {
    loader("show");
    if (typeof finalTags != "undefined" && finalTags.length > 0) {
      if (typeof tagClickedFirst != "undefined" && tagClickedFirst.length > 0) {
        let prev_tags = finalTags;
        let new_tags = prev_tags.concat(tagClickedFirst);
        const uniqueTags = new_tags.filter((x, i, a) => a.indexOf(x) == i);
        setFinalTags(uniqueTags);
      } else {
        setFinalTags(tagClickedFirst);
      }
    } else {
      setFinalTags(tagClickedFirst);
    }
    setupdateFlag(updateflag + 1);
    closeModal();
    loader("hide");
  };

  const handleOnEbookChange = (e, i) => {
    const value = e.target.files[0]?.name;
    const list = [...chapter];
    list[i].uploadFile = value;
    list[i].uploadNewFile = value;
    list[i].selectedVideo = '';
    ebookFile[i] = e.target.files[0];
    setEbookFile(ebookFile);
    setChapter(list);
  };

  const onChapterSelect = (event) => {
    setChapterSelect(event);
  };

  const onVideoSelect = (event) => {
    setVideoSelect(event);
  };

  const onUploadNewVideoClicked = () => {
    setUploadNewVideo(true);
  };
  const onChangeEmbeddedVideo = (event) => {
    setChangeEmbeddedVideo(event);
  };
  const addNewProductClicked = (e) => {
    e.preventDefault();
    setCommanShow(true);
  };

  const topicButtonClicked = (group_id) => {
    setIsOpen(true);
  };

  const onSelectType = async(e,i,type) => {
    const list = [...chapter];
    list[i].type = type;
    setChapter(list);
  }

  // const onSelectVideoType = async(e,i,type) => {
  //   // if(type == 'existing' && getVideoArticle.length == 0){
    
  //   // }
  //   const list = [...chapter];
  //   list[i].videoType = type;
  //   setChapter(list);
  // }

  const onSelectVideoType = async (e, i, type) => {
    if (type == 'existing' && getVideoArticle.length == 0) {
      const requestBody = {
        selectValue: JSON.stringify(["id", "title", "code"]),
        file_type: "'video'",
      };
      const response = await postData(ENDPOINT.LIBRARY, requestBody);
      const hadData = response?.data?.data?.library || [];
      const pdfObj = hadData
        .map((item) => ({
          label: item.title.trim(),
          value: item.id,
        }))
        .sort((a, b) =>
          a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        );
      setVideoArticle(pdfObj);
    }
    const list = [...chapter];
    list[i].videoType = type;
    list[i].uploadFile ="";
    list[i].selectedVideo = "";
    setChapter(list);
  }

  const handleVideoChange = (value,i) => {
    const list = [...chapter];
    list[i].selectedVideo = value;
    list[i].videoThumb = '';
    list[i].uploadFile = '';
    setChapter(list);
  };

  const handleOnVideoThumbChange = (e, i) => {
    const value = e.target.files[0]?.name;
    const list = [...chapter];
    list[i].videoThumb = value;
    list[i].videoNewThumb = value;
    videoThumb[i] = e.target.files[0];
    setVideoThumb(videoThumb);
    setChapter(list);
  };

  const publisherFun = () => {
    return (
      <div className="create-change-content">
        <div className="form_action">
          <h4>Who is involved</h4>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">Company</label>
                <input
                  type="text"
                  className="form-control"
                  name="company"
                  defaultValue={userInputs?.company}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group margin-added">
                <label htmlFor="">Client product</label>
                <Select
                  options={userDetail?.product}
                  defaultValue={{
                    label: userInputs?.product,
                    value: userInputs?.product,
                  }}
                  onChange={(e) => handleChange(e?.value, "product")}
                  placeholder="Select product"
                  className="dropdown-basic-button split-button-dropup edit-production-dropdown"
                  isClearable
                />
                <div className="add_product">
                  <span>&nbsp;</span>
                  <Button
                    onClick={addNewProductClicked}
                    className="btn-bordered btn-voilet"
                  >
                    Add New Product +
                  </Button>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="">Country</label>
                <Select
                  options={userDetail?.country || []}
                  placeholder="Select country"
                  defaultValue={{
                    label: userInputs?.country,
                    value: userInputs?.country,
                  }}
                  onChange={(e) => handleChange(e?.value, "country")}
                  className="dropdown-basic-button split-button-dropup"
                  isClearable
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Production</label>
                <Select
                  options={userDetail?.production}
                  onChange={(e) => handleChange(e?.id, "production_id")}
                  defaultValue={
                    userDetail?.production?.length
                      ? userDetail?.production[
                          userDetail?.production?.findIndex(
                            (el) => el.id == userInputs?.production_id
                          )
                        ]
                      : ""
                  }
                  placeholder="Select own production person"
                  className="dropdown-basic-button split-button-dropup edit-production-dropdown"
                  isClearable
                />
              </div>

              {
                 userDetail?.user?.[0]?.retailer == 1  ? 
                  <div className="form-group">
                    <label htmlFor="">Pharma</label>
                    <Select
                      options={userDetail?.pharmaArr}
                      onChange={(e) => handleChange(e?.value, "pharmaArr")}
                      defaultValue={
                        userDetail?.pharmaArr?.length
                          ? userDetail?.pharmaArr[
                            userDetail?.pharmaArr?.findIndex(
                                (el) => el.value == userInputs?.pharmaArr
                              )
                            ]
                          : ""
                      }
                      placeholder="Select own production person"
                      className="dropdown-basic-button split-button-dropup edit-production-dropdown"
                      isClearable
                    />
                  </div>
                 : null
              }
              {localStorage.getItem("user_id") ==
                "rOhdD02MgXkownQqcreqAw==" && (
                <div className="form-group">
                  <label htmlFor="">Sales</label>
                  <Select
                    options={userDetail?.sales}
                    defaultValue={
                      userDetail?.sales?.length
                        ? userDetail?.sales[
                            userDetail?.sales?.findIndex(
                              (el) => el?.id == userInputs?.sales_id
                            )
                          ]
                        : ""
                    }
                    placeholder="Who made the sale?"
                    onChange={(e) => handleChange(e?.id, "sales_id")}
                    className="dropdown-basic-button split-button-dropup edit-sales-dropdown"
                    isClearable
                  />
                </div>
              )}
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-end align-items-end right-change">
              <div className="form-group justify-content-end">
                <label htmlFor="">Reseller</label>
                <div className="form-check-group">
                  <div className="form-check-group-inset">
                    {userDetail?.reseller?.length ? (
                      userDetail?.reseller?.map((item) => {
                        return (
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              value=""
                              id="flexCheckDefault"
                              type="checkbox"
                              defaultChecked={reseller.includes(item?.id)}
                              onClick={(e) => handleReseller(e, item)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              {item?.value}
                            </label>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            value=""
                            id="flexCheckReseller"
                            type="checkbox"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckReseller"
                          >
                            N/A
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const docintelLink = () => {
    return (
      <div className="create-change-content">
        <div className="form_action">
          <h4>About the Docintel link you're making</h4>
          <div className="row">
            <div className="col-12 col-md-6">
              {userDetail?.user?.[0]?.flag != 1 &&
              userDetail?.user?.[0]?.group_id == 3 ? (
                <>
                  <div className="form-group">
                    <label htmlFor="">Category</label>
                    <Select
                      options={userDetail?.category || []}
                      placeholder="Select category type for HCPs to sort"
                      defaultValue={{
                        label:
                          userInputs?.category == 0 ? "" : userInputs?.category,
                        value:
                          userInputs?.category == 0 ? "" : userInputs?.category,
                      }}
                      onChange={(e) => handleChange(e?.value, "category")}
                      className="dropdown-basic-button split-button-dropup"
                      isClearable
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Format</label>
                    <Select
                      options={userDetail?.format || []}
                      placeholder="Select format for tracking"
                      defaultValue={{
                        label: userInputs?.format,
                        value: userInputs?.format,
                      }}
                      onChange={(e) => handleChange(e?.value, "format")}
                      className="dropdown-basic-button split-button-dropup"
                      isClearable
                    />
                  </div>
                </>
              ) : null}
              {userDetail?.user?.[0]?.flag == 0 &&
              userDetail?.user?.[0]?.group_id == 3 ? (
                <div className="form-group margin-added">
                  <label htmlFor="">Product</label>
                  <Select
                    options={userDetail?.product || []}
                    placeholder="Select the product this is for"
                    defaultValue={{
                      label: userInputs?.product,
                      value: userInputs?.product,
                    }}
                    onChange={(e) => handleChange(e?.value, "product")}
                    className="dropdown-basic-button split-button-dropup"
                    isClearable
                  />
                  <div className="add_product">
                    <span>&nbsp;</span>
                    <Button
                      onClick={addNewProductClicked}
                      className="btn-bordered btn-voilet"
                    >
                      Add New Product +
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  {/*<div className="form-group">
                      <label htmlFor="">Trial*</label>

                      <Select
                        options={userDetail?.trial || []}
                        placeholder="Select the trial "
                        defaultValue={{
                          label: userInputs?.trial
                            ? userDetail?.trial?.[0].value == userInputs?.trial
                              ? userDetail?.trial?.[0].label
                              : ""
                            : "",
                          value: userInputs?.trial
                            ? userDetail?.trial?.[0].value == userInputs?.trial
                              ? userDetail?.trial?.[0].value
                              : ""
                            : "",
                        }}
                        onChange={(e) => handleChange(e?.value, "trial")}
                        className="dropdown-basic-button split-button-dropup"
                        isClearable
                      />

                      {error?.trial ? (
                        <div className="login-validation">{error?.trial}</div>
                      ) : null}
                    </div>*/}
                </>
              )}
              {userDetail?.user?.[0]?.pharmaData == 1 &&
              userDetail?.user?.[0]?.group_id == 3 ? (
                <div className="form-group">
                  <label htmlFor="">Business Unit</label>
                  <Select
                    options={userDetail?.ibu || []}
                    placeholder="Select Business Unit"
                    defaultValue={{
                      label: userInputs?.ibu,
                      value: userInputs?.ibu,
                    }}
                    onChange={(e) => handleChange(e?.value, "ibu")}
                    className="dropdown-basic-button split-button-dropup"
                    isClearable
                  />
                </div>
              ) : userDetail?.user?.[0]?.flag == 1 &&
                userDetail?.user?.[0]?.group_id == 3 ? (
                <>
                  {/*<div className="form-group">
                      <label htmlFor="">Blind type*</label>
                      <Select
                        options={blindType || []}
                        placeholder="Select Blind Type"
                        defaultValue={{
                          label: userInputs?.blindType,
                          value: userInputs?.blindType,
                        }}
                        onChange={(e) => handleChange(e?.value, "blindType")}
                        className="dropdown-basic-button split-button-dropup"
                        isClearable
                      />
                      {error?.blindType ? (
                        <div className="login-validation">{error?.blindType}</div>
                      ) : null}
                    </div>*/}
                </>
              ) : null}

              {userDetail?.user?.[0]?.flag == 0 &&
              userDetail?.user?.[0]?.pharmaData == 0 &&
              userDetail?.user?.[0]?.octaLach == 1 &&
              userDetail?.user?.[0]?.group_id == 3 ? (
                <div className="form-group">
                  <label htmlFor="">Content Use</label>
                  <fieldset id="group2">
                    <input
                      type="radio"
                      value="value2"
                      name="group2"
                      defaultChecked={
                        userInputs?.article_platform == 1 ? true : false
                      }
                      onClick={(e) => handleChange(1, "allowLibrary")}
                      id="limitagreed2"
                    />
                    <label htmlFor="limitagreed2">Library</label>

                    <input
                      type="radio"
                      value="value1"
                      name="group2"
                      defaultChecked={
                        userInputs?.article_platform == 2 ? true : false
                      }
                      onClick={(e) => handleChange(2, "allowLibrary")}
                      id="limitagreed1"
                    />
                    <label htmlFor="limitagreed1">Congress</label>
                  </fieldset>
                </div>
              ) : null}

              {userDetail?.user?.[0]?.flag == 1 &&
              userDetail?.user?.[0]?.group_id == 3 ? (
                <>
                  <div className="form-group justify-content-start rd">
                    <label htmlFor="">Topics</label>
                    <div className="input-group w-100">
                      <div className="input-group-prepend">
                        <button
                          className="btn btn-filled btn-primary"
                          type="button"
                          id="tags-add"
                          data-bs-toggle="modal"
                          data-bs-target="#tagsModal"
                          onClick={(e) =>
                            topicButtonClicked(userDetail?.user[0]?.group_id)
                          }
                        >
                          Add Topic +
                        </button>
                      </div>
                      <div className="tags_added">
                        <ul>
                          {finalTags?.map((item, index) => {
                            return (
                              <li className="list1">
                                {item}
                                <img
                                  src="componentAssets/images/filter-close.svg"
                                  alt="Close-filter"
                                  onClick={() => removeTagFinal(index)}
                                />
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            {localStorage.getItem("user_id") != "56Ek4feL/1A8mZgIKQWEqg==" && localStorage.getItem("user_id") != "sNl1hra39QmFk9HwvXETJA==" ? (
              <div className="col-12 col-md-6 d-flex justify-content-start align-items-start right-change flex-column">
                <div className="form-group justify-content-end">
                  <label htmlFor="">Topics</label>
                  <div className="input-group w-100">
                    <div className="input-group-prepend">
                      <button
                        className="btn btn-filled btn-primary"
                        type="button"
                        id="tags-add"
                        data-bs-toggle="modal"
                        data-bs-target="#tagsModal"
                        onClick={(e) =>
                          topicButtonClicked(userDetail?.user[0]?.group_id)
                        }
                      >
                        Add Topic +
                      </button>
                    </div>
                    <div className="tags_added">
                      <ul>
                        {finalTags?.map((item, index) => {
                          return (
                            <li className="list1">
                              {item}
                              <img
                                src="componentAssets/images/filter-close.svg"
                                alt="Close-filter"
                                onClick={() => removeTagFinal(index)}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const handleModelFun = (e) => {
    setUserDetail({ ...userDetail, newValue: e.target.value });
  };
  const handleSubmitModelFun = async (e) => {
    try {
      let newAr = userDetail?.product;
      newAr.push({ value: userDetail?.newValue, label: userDetail?.newValue });
      let body = {
        user_id: id,
        product: userDetail?.newValue,
        category: 0,
        type: 1,
      };
      const res = await postData(ENDPOINT.ADD_SPC_PRODUCT, body);
      setUserDetail({ ...userDetail, product: newAr });

      toast.success(res?.data?.message);
    } catch (err) {
      loader("hide");
    }
  };
  const LimitAgreed = () => {
    return (
      <div className="create-change-content">
        <div className="form_action">
          <h4>Limits agreed</h4>
          <div className="row">
            <div className="col-12 col-md-6">
              {/* {userDetail?.costCenter ? (
                <div className="form-group">
                  <label htmlFor="">Cost centre</label>
                  <Select
                    options={userDetail?.costCenter}
                    defaultValue={{
                      label: userInputs?.cost_center,
                      value: userInputs?.cost_center,
                    }}
                    className="dropdown-basic-button split-button-dropup"
                    isClearable
                    placeholder="Select cost center"
                    onChange={(e) => handleChange(e?.value, "cost_center")}
                  />
                </div>
              ) : (
                ""
              )} */}

              {/* <div className="form-group">
                <label htmlFor="">Expiration date</label>
                <DatePicker
                  selected={
                    userInputs?.expDatetime
                      ? new Date(userInputs?.expDatetime)
                      : new Date(
                          moment(new Date(), "MM/DD/YYYY")
                            .add("years", 1)
                            .format("MM/DD/YYYY")
                        )
                  }
                  name="expDatetime"
                  onChange={(e) => handleChange(e, "expDatetime")}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  minDate={currentDate}
                />
              </div> */}

              {/* <div className="form-group">
                <label htmlFor="">Set limit of usage</label>
                <input
                  type="number"
                  name="limit"
                  min="0"
                  defaultValue={Number(userInputs?.limit)}
                  className={
                    error?.limit ? "form-control error" : "form-control"
                  }
                  ref={limitFieldRef}
                  // className="form-control"
                  placeholder="“0” value means unlimited limit"
                  onChange={handleChange}
                />
                {error?.limit ? (
                  <div className="login-validation">{error?.limit}</div>
                ) : null}
              </div> */}
              <div className="form-group">
                <label htmlFor="">Allow</label>
                <fieldset id="group2">
                  <input
                    type="checkbox"
                    value="value1"
                    name="group2"
                    defaultChecked={userInputs?.allow_print}
                    onClick={(e) =>
                      handleChange(e.target?.checked, "allow_print")
                    }
                    id="limitagreed1"
                  />
                  <label htmlFor="limitagreed1">Print</label>
                  <input
                    type="checkbox"
                    value="value2"
                    name="group2"
                    defaultChecked={userInputs?.allow_download}
                    onClick={(e) =>
                      handleChange(e.target?.checked, "allow_download")
                    }
                    id="limitagreed2"
                  />
                  <label htmlFor="limitagreed2">Download</label>
                  {/*
                    <input
                      type="checkbox"
                      value="value3"
                      defaultChecked={libraryData?.allow_share}
                      onClick={(e) =>
                        handleChange(e.target?.checked, "allow_share")
                      }
                      name="group2"
                      id="limitagreed3"
                    />
                    <label htmlFor="limitagreed3">Share</label>
                    */}
                </fieldset>
              </div>

              {
                userDetail?.user?.[0]?.retailer == 1  ? 
                  <>
                    <div className="form-group">
                      <label htmlFor="">Status 
                      {/* <span>*</span> */}
                      </label>
                      <fieldset id="group2">
                        <input
                          type="radio"
                          value="sold"
                          name="sold_unsold_status"
                          checked={userInputs?.sold_unsold_status == 'sold' ? true : false}
                          onClick={(e) =>
                            handleChange('sold', "sold_unsold_status")
                          }
                          id="sold"
                        />
                        <label htmlFor="sold">Sold</label>
                        <input
                          type="radio"
                          value="unsold"
                          name="sold_unsold_status"
                          checked={userInputs?.sold_unsold_status == 'unsold' ? true : false}
                          onClick={(e) =>
                            handleChange('unsold', "sold_unsold_status")
                          }
                          id="unsold"
                        />
                        <label htmlFor="unsold">Unsold</label>
                      </fieldset>
                      {error?.status ? (
                        <div className="login-validation">
                          {error?.status}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                    <label htmlFor="setasdraft">Request quote</label>
                    <fieldset id="request_quote">
                      <div className="switch">
                        <label className="switch-light">
                          <input
                            type="checkbox"
                            value="value1"
                            name="request_quote"
                            id="setasdraft"
                            defaultChecked={
                              userInputs?.request_quote ? true : false
                            }
                            onChange={(e) => {
                              handleChange(e.target?.checked, "request_quote");
                            }}
                          />
                          <span>
                            <span
                              className={`switch-btn ${
                                userInputs?.request_quote == 0
                                  ? " Active"
                                  : ""
                              }`}
                            >
                              No
                            </span>
                            <span
                              className={`switch-btn ${
                                userInputs?.draft == 1
                                  ? " Active"
                                  : ""
                              }`}
                            >
                              Yes
                            </span>
                          </span>
                          <a className="btn"></a>
                        </label>
                      </div>
                    </fieldset>
                    </div>
                  </>
                
                : null
              }

            </div>
            <div className="col-12 col-md-6 d-flex justify-content-end align-items-start right-change">
              <div className="form-group justify-content-end">
                <label htmlFor="">Invoice notes</label>
                <textarea
                  className="form-control"
                  id="formControlTextarea"
                  defaultValue={userInputs?.specialRequirment}
                  onChange={(e) =>
                    handleChange(e?.target?.value, "specialRequirment")
                  }
                  rows="5"
                  placeholder="Please type your notes here..."
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Col className="right-sidebar custom-change">
        {showFlag ? (
          <div className="custom-container">
            <Row>
              <div className="page-top-nav sticky">
                <div className="row justify-content-end align-items-center">
                  <div className="col-12 col-md-1">
                    <div className="header-btn-left">
                      {/*<Link
                          className="btn btn-primary btn-bordered back-btn"
                          to="/library-content"
                        >
                          <svg
                            width="14"
                            height="24"
                            viewBox="0 0 14 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.159662 12.0019C0.159662 11.5718 0.323895 11.1417 0.65167 10.8138L10.9712 0.494292C11.6277 -0.16216 12.692 -0.16216 13.3482 0.494292C14.0044 1.15048 14.0044 2.21459 13.3482 2.8711L4.21687 12.0019L13.3479 21.1327C14.0041 21.7892 14.0041 22.8532 13.3479 23.5093C12.6917 24.1661 11.6274 24.1661 10.9709 23.5093L0.65135 13.19C0.323523 12.8619 0.159662 12.4319 0.159662 12.0019Z"
                              fill="#97B6CF"
                            />
                          </svg>
                        </Link>*/}
                    </div>
                  </div>
                  <div className="col-12 col-md-9">
                    <ul className="tabnav-link">
                      <li className="active active-main">
                        <a href="">Edit Your Content</a>
                      </li>
                      {userInputs?.allow_video ? (
                        <li className="">
                          <a href="">[Embedding Video]</a>
                        </li>
                      ) : null}
                      {localStorage.getItem("user_id") !=
                      "56Ek4feL/1A8mZgIKQWEqg==" && localStorage.getItem("user_id") != "sNl1hra39QmFk9HwvXETJA==" ? (
                        <li className="">
                          <a href="">Edit Consent Option</a>
                        </li>
                      ) : null}

                      <li className="">
                        <a href="">Approve Your Content &amp; Save</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="header-btn">
                      <Link
                        className="btn btn-primary btn-bordered move-draft"
                        // to="/library-create"
                        to={
                          location?.state?.flag === "mandatory"
                            ? "/library-mandatory-content"
                            : location?.state?.flag === "Non-mandatory"
                            ? "/library-content"
                            : "/library-create"
                        }
                        state={{ 
                          // title: localStorage.getItem("user_id") ==="56Ek4feL/1A8mZgIKQWEqg==" 
                          // ? (location?.state?.title)
                          // : '' 
                          flag : localStorage.getItem("user_id") ==="56Ek4feL/1A8mZgIKQWEqg=="||  localStorage.getItem("user_id") ==="sNl1hra39QmFk9HwvXETJA==" ?(location?.state?.flag === "mandatory"
                            ? "mandatory"
                            : location?.state?.flag === "Non-mandatory"
                            ? "Non-mandatory" : '') :'',
                            title: localStorage.getItem("user_id") ==="56Ek4feL/1A8mZgIKQWEqg==" ||  localStorage.getItem("user_id") ==="sNl1hra39QmFk9HwvXETJA=="
                            ? (location?.state?.title)
                            : ''
                        }}
                      >
                        Cancel
                      </Link>

                      <button
                        className="btn btn-primary btn-filled next"
                        onClick={nextButtonClicked}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {userDetail?.user?.[0]?.group_id == 2
                ? publisherFun()
                : userDetail?.user?.[0]?.flag == 0 &&
                  userDetail?.user?.[0]?.group_id == 3
                ? docintelLink()
                : userDetail?.user?.[0]?.flag == 1 &&
                  userDetail?.user?.[0]?.group_id == 3
                ? docintelLink()
                : null}
              {userDetail?.user?.[0]?.group_id == 2 ? LimitAgreed() : null}

              <div className="create-change-content">
                <div className="form_action">
                  {userDetail?.user?.[0]?.group_id == 2 ? (
                    <h4>Creating the eprint</h4>
                  ) : userDetail?.user?.[0]?.flag == 0 &&
                    userDetail?.user?.[0]?.group_id == 3 ? (
                    <h4>Creating the Docintel Link</h4>
                  ) : userDetail?.user?.[0]?.flag == 1 &&
                    userDetail?.user?.[0]?.group_id == 3 ? (
                    <h4>Creating the Docintel Link</h4>
                  ) : null}

                  <Row>
                    <Col md={6}>
                      <div className="form-group val">
                        <label htmlFor="">
                          Content title <span>*</span>
                        </label>
                        <input
                          type="text"
                          name="contentTitle"
                          className={
                            error?.contentTitle
                              ? "form-control error"
                              : "form-control"
                          }
                          ref={titleFieldRef}
                          defaultValue={userInputs?.contentTitle}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        {error?.contentTitle ? (
                          <div className="login-validation">
                            {error?.contentTitle}
                          </div>
                        ) : null}
                      </div>
                      {userDetail?.user?.[0]?.flag == 1 &&
                      userDetail?.user?.[0]?.group_id == 3 ? (
                        <div className="form-group">
                          <label htmlFor="">Comment</label>
                          {/*<input
                                type="text"
                                name="journalTitle"
                                defaultValue={userInputs?.journalTitle}
                                className="form-control"
                                onChange={(e) => handleChange(e)}
                              />*/}
                          <textarea
                            className={
                              error?.journalTitle
                                ? "form-control error"
                                : "form-control"
                            }
                            id="formControlTextarea"
                            name="journalTitle"
                            rows="5"
                            defaultValue={userInputs?.journalTitle}
                            onChange={(e) => handleChange(e)}
                            placeholder="Please type your comments here..."
                          ></textarea>

                          {error?.journalTitle ? (
                            <div className="login-validation">
                              {error?.journalTitle}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="form-group">
                          {userDetail?.user?.[0]?.flag == 0 &&
                          userDetail?.user?.[0]?.group_id == 3 ? (
                            <label htmlFor="">Sub title</label>
                          ) : (
                            <label htmlFor="">Journal title</label>
                          )}

                          <input
                            type="text"
                            name="journalTitle"
                            defaultValue={userInputs?.journalTitle}
                            className={
                              error?.journalTitle
                                ? "form-control error"
                                : "form-control"
                            }
                            onChange={(e) => handleChange(e)}
                          />
                          {error?.journalTitle ? (
                            <div className="login-validation">
                              {error?.journalTitle}
                            </div>
                          ) : null}
                        </div>
                      )}

                      {localStorage.getItem("user_id") ==
                      "B7SHpAc XDXSH NXkN0rdQ==" ? (
                        <>
                          <div className="form-group">
                            <label htmlFor="">Language</label>
                            <Select
                              options={language}
                              placeholder="Select language"
                              defaultValue={{
                                label: userInputs?.language,
                                value: userInputs?.language,
                              }}
                              onChange={(e) =>
                                handleChange(e?.value, "language")
                              }
                              className="dropdown-basic-button split-button-dropup"
                              isClearable
                            />
                          </div>
                        </>
                      ) : null}

                      {localStorage.getItem("user_id") !=
                        "iSnEsKu5gB/DRlycxB6G4g==" &&
                      localStorage.getItem("user_id") !=
                        "56Ek4feL/1A8mZgIKQWEqg==" && localStorage.getItem("user_id") != "sNl1hra39QmFk9HwvXETJA==" ? (
                        <div className="form-group">
                          <label htmlFor="">Author</label>
                          <input
                            type="text"
                            name="keyAuthor"
                            defaultValue={userInputs?.keyAuthor}
                            className="form-control"
                            onChange={handleChange}
                          />
                        </div>
                      ) : null}

                      {userDetail?.user?.[0]?.flag == 1 &&
                      userDetail?.user?.[0]?.group_id == 3 ? (
                        <>
                          {
                            <div className="form-group">
                              <label htmlFor="setasdraft1">Set as draft</label>
                              <fieldset id="group2">
                                <div className="switch">
                                  <label className="switch-light">
                                    <input
                                      type="checkbox"
                                      name="group2"
                                      id="setasdraft1"
                                      defaultChecked={
                                        userInputs?.draft ? true : false
                                      }
                                      onChange={(e) => {
                                        handleChange(
                                          e.target?.checked,
                                          "draft"
                                        );
                                      }}
                                    />
                                    <span>
                                      <span
                                        className={`switch-btn ${
                                          userInputs?.draft == 0
                                            ? " Active"
                                            : ""
                                        }`}
                                      >
                                        No
                                      </span>
                                      <span
                                        className={`switch-btn ${
                                          userInputs?.draft == 1
                                            ? " Active"
                                            : ""
                                        }`}
                                      >
                                        Yes
                                      </span>
                                    </span>
                                    <a className="btn"></a>
                                  </label>
                                </div>
                              </fieldset>
                            </div>
                          }
                        </>
                      ) : null}

                      {userDetail?.user?.[0]?.flag == 0 &&
                      userDetail?.user?.[0]?.group_id == 3 ? (
                        <>
                          <div className="form-group">
                            <label htmlFor="">Allowed</label>
                            <fieldset id="group2">
                              <input
                                type="checkbox"
                                value="value1"
                                name="group2"
                                defaultChecked={userInputs?.allow_print}
                                onClick={(e) =>
                                  handleChange(e.target?.checked, "allow_print")
                                }
                                id="limitagreed1"
                              />
                              <label htmlFor="limitagreed1">Print</label>
                              <input
                                type="checkbox"
                                value="value2"
                                name="group2"
                                defaultChecked={userInputs?.allow_download}
                                onClick={(e) =>
                                  handleChange(
                                    e.target?.checked,
                                    "allow_download"
                                  )
                                }
                                id="limitagreed2"
                              />
                              <label htmlFor="limitagreed2">Download</label>
                              <input
                                type="checkbox"
                                value="value3"
                                defaultChecked={userInputs?.allow_share}
                                onClick={(e) =>
                                  handleChange(e.target?.checked, "allow_share")
                                }
                                name="group2"
                                id="limitagreed3"
                              />
                              <label htmlFor="limitagreed3">Share</label>
                              <input
                                type="checkbox"
                                value="value4"
                                name="group2"
                                defaultChecked={userInputs?.chat_box}
                                onClick={(e) =>
                                  handleChange(e.target?.checked, "chat_box")
                                }
                                id="limitagreed4"
                              />
                              <label htmlFor="limitagreed4">Request</label>
                            </fieldset>
                          </div>
                          <div className="form-group">
                            <label htmlFor="setasdraft2">Set as draft</label>
                            <fieldset id="group2">
                              <div className="switch">
                                <label className="switch-light">
                                  <input
                                    type="checkbox"
                                    name="group2"
                                    id="setasdraft2"
                                    defaultChecked={
                                      userInputs?.draft ? true : false
                                    }
                                    onChange={(e) => {
                                      handleChange(e.target?.checked, "draft");
                                    }}
                                  />
                                  <span>
                                    <span
                                      className={`switch-btn ${
                                        userInputs?.draft == 0 ? " Active" : ""
                                      }`}
                                    >
                                      No
                                    </span>
                                    <span
                                      className={`switch-btn ${
                                        userInputs?.draft == 1 ? " Active" : ""
                                      }`}
                                    >
                                      Yes
                                    </span>
                                  </span>
                                  <a className="btn"></a>
                                </label>
                              </div>
                            </fieldset>
                          </div>
                        </>
                      ) : null}

                      {userDetail?.user?.[0]?.octaLach == 1 &&
                      userDetail?.user?.[0]?.group_id == 3 ? (
                        <>
                          <div className="form-group">
                            <label htmlFor="setasdraft3">Medical</label>
                            <fieldset id="group2">
                              <div className="switch">
                                <label className="switch-light">
                                  <input
                                    type="checkbox"
                                    value="value1"
                                    name="group2"
                                    id="setasdraft3"
                                    defaultChecked={
                                      userInputs?.medical &&
                                      parseInt(userInputs?.medical)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      handleChange(
                                        e.target?.checked,
                                        "medical"
                                      );
                                    }}
                                  />
                                  <span>
                                    <span
                                      className={`switch-btn ${
                                        userInputs?.medical == 0
                                          ? " Active"
                                          : ""
                                      }`}
                                    >
                                      No
                                    </span>
                                    <span
                                      className={`switch-btn ${
                                        userInputs?.medical == 1
                                          ? " Active"
                                          : ""
                                      }`}
                                    >
                                      Yes
                                    </span>
                                  </span>
                                  <a className="btn"></a>
                                </label>
                              </div>
                            </fieldset>
                          </div>
                        </>
                      ) : null}

                      {userDetail?.user?.[0]?.octaLach == 1 && userDetail?.user?.[0]?.group_id == 3
                         ? (
                        <>
                          <div className="form-group">
                            <label htmlFor="synconesource">
                              <img src={path_image + "library_move.svg"} />
                            </label>
                            <fieldset id="sync_onesource">
                              <div className="switch">
                                <label className="switch-light">
                                  <input
                                    type="checkbox"
                                    value="value1"
                                    name="sync_onesource"
                                    id="synconesource"
                                    defaultChecked={
                                      userInputs?.sync_onesource &&
                                      parseInt(userInputs?.sync_onesource)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      handleChange(
                                        e.target?.checked,
                                        "sync_onesource"
                                      );
                                    }}
                                  />
                                  <span>
                                    <span
                                      className={`switch-btn ${
                                        userInputs?.sync_onesource == 0
                                          ? " Active"
                                          : ""
                                      }`}
                                    >
                                      No
                                    </span>
                                    <span
                                      className={`switch-btn ${
                                        userInputs?.sync_onesource == 1
                                          ? " Active"
                                          : ""
                                      }`}
                                    >
                                      Yes
                                    </span>
                                  </span>
                                  <a className="btn"></a>
                                </label>
                              </div>
                            </fieldset>
                          </div>
                        </>
                      ) : null}

                      {userDetail?.user?.[0]?.flag == 1 && userDetail?.user?.[0]?.group_id == 3 ? (
                        <>
                          {localStorage.getItem("user_id") !== "56Ek4feL/1A8mZgIKQWEqg==" &&  localStorage.getItem("user_id") !=="sNl1hra39QmFk9HwvXETJA=="? (
                            <div className="form-group">
                              <label htmlFor="setasdraft4">
                                {localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" ||
                                localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA=="
                                  ? "IRT mandatory training"
                                  : "Mandatory"}
                              </label>
                              <fieldset id="group2">
                                <div className="switch">
                                  <label className="switch-light">
                                    <input
                                      type="checkbox"
                                      name="group2"
                                      placeholder={
                                        localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" ||
                                        localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA=="
                                          ? "Select IRT mandatory training"
                                          : "Select IRT"
                                      }
                                      id="setasdraft4"
                                      defaultChecked={userInputs?.reader_mandatory ? true : false}
                                      onChange={(e) => {
                                        handleChange(e.target?.checked, "reader_mandatory");
                                      }}
                                    />
                                    <span>
                                      <span
                                        className={`switch-btn ${
                                          userInputs?.draft == 0 ? " Active" : ""
                                        }`}
                                      >
                                        No
                                      </span>
                                      <span
                                        className={`switch-btn ${
                                          userInputs?.draft == 1 ? " Active" : ""
                                        }`}
                                      >
                                        Yes
                                      </span>
                                    </span>
                                    <a className="btn"></a>
                                  </label>
                                </div>
                              </fieldset>
                            </div>
                          ) : null}
                        </>
                      ) : null}

                      {userDetail?.user?.[0]?.flag == 1 &&
                      (!userInputs?.reader_mandatory ||
                        userInputs?.reader_mandatory == 0) &&
                      userDetail?.user?.[0]?.group_id == 3 ? (
                        <div className="form-group">
                          <label htmlFor="">Role</label>
                          <div className="input-group w-100">
                            <div className="tags_added">
                              <div className="select-tags">
                                <ul>
                                  <>
                                    <li
                                      className={
                                        hcpClickedFirst.length == 5
                                          ? "list1 all fade-down"
                                          : "list1 all fade-up"
                                      }
                                      onClick={() => {
                                        hcpClicked("All");
                                      }}
                                    >
                                      All
                                    </li>
                                    {userDetail?.hcp?.map((item, index) => {
                                      return (
                                        <li
                                          className="list1"
                                          onClick={() => {
                                            hcpClicked(item);
                                          }}
                                        >
                                          {item}
                                        </li>
                                      );
                                    })}
                                  </>
                                </ul>
                                <div className="after-selected">
                                  <ul className="after-tag-selected">
                                    {hcpClickedFirst.map((item, index) => {
                                      return (
                                        <>
                                          {userDetail?.hcp?.includes(item) ? (
                                            <li className="list1">
                                              {item}
                                              <img
                                                src="componentAssets/images/filter-close.svg"
                                                alt="Close-filter"
                                                onClick={() => removeHcp(item)}
                                              />
                                            </li>
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {location.state?.flag !== 'mandatory' && userDetail?.user?.[0]?.flag == 1 &&
                      userInputs?.reader_mandatory == 1 &&
                      userDetail?.user?.[0]?.group_id == 3 ? (
                        <div className="form-group">
                          <label htmlFor="">IRT role</label>
                          <div className="input-group w-100">
                            <div className="tags_added">
                              <div className="select-tags">
                                <ul className="after-tag-selected">
                                  <>
                                    {mandatoryRole.map((item, index) => {
                                      return (
                                        <li
                                          className="list1"
                                          onClick={() => {
                                            hcpIrtClicked(item);
                                          }}
                                        >
                                          {item}
                                        </li>
                                      );
                                    })}
                                  </>
                                </ul>
                                <div className="after-selected">
                                  <ul className="after-tag-selected">
                                    {hcpIrtClickedFirst.map((item, index) => {
                                      return (
                                        <>
                                          {mandatoryRole.includes(item) ? (
                                            <li className="list1">
                                              {item}
                                              <img
                                                src="componentAssets/images/filter-close.svg"
                                                alt="Close-filter"
                                                onClick={() =>
                                                  removeHcp(item, "irt")
                                                }
                                              />
                                            </li>
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="form-group val">
                        <label htmlFor="">
                          Docintel format <span>*</span>
                        </label>
                        <Select
                          className={
                            error?.docintelFormat
                              ? "dropdown-basic-button split-button-dropup error"
                              : "dropdown-basic-button split-button-dropup"
                          }
                          options={ePrintType}
                          value={ePrintType.find(
                            (item) => item.value == userInputs?.docintelFormat
                          )}
                          isClearable
                          placeholder="Select type of Docintel format "
                          onChange={(event) =>
                            handleChange(event?.value, "docintelFormat")
                          }
                        />
                        {error?.docintelFormat ? (
                          <div className="login-validation">
                            {error?.docintelFormat}
                          </div>
                        ) : null}
                      </div>

                      {userInputs.docintelFormat == "pdf" ? (
                        <div className="form-group val">
                          <label htmlFor="">Upload PDF</label>
                          <div className="upload-file-box">
                            <div className="box">
                              <input
                                type="file"
                                name="file-6[]"
                                id="file-6"
                                className={
                                  error?.uploadFile
                                    ? "inputfile inputfile-6 error"
                                    : "inputfile inputfile-6"
                                }
                                accept="application/pdf"
                                onChange={(e) => handleChange(e, "uploadFile")}
                              />
                              <label htmlFor="file-6">
                                <span>Change Your File</span>
                              </label>

                              {userInputs?.uploadFile?.[0]?.name ? (
                                <p className="uploaded-file">
                                  {userInputs?.uploadFile?.[0].name}
                                </p>
                              ) : (
                                <p>Change your PDF</p>
                              )}
                            </div>
                          </div>
                          {error?.uploadFile ? (
                            <div className="login-validation-upload">
                              {error?.uploadFile}
                            </div>
                          ) : null}
                        </div>
                      ) : userInputs?.docintelFormat == "video" ? (
                        <div className="form-group val">
                          <label htmlFor="">Upload video</label>
                          <div className="upload-file-box">
                            <div className="box">
                              <input
                                type="file"
                                name="file-6[]"
                                id="file-6"
                                className={
                                  error?.uploadFile
                                    ? "inputfile inputfile-6 error"
                                    : "inputfile inputfile-6"
                                }
                                accept="video/mp4"
                                onChange={(e) => handleChange(e, "uploadFile")}
                              />
                              <label htmlFor="file-6">
                                <span>Change Your File</span>
                              </label>
                              {userInputs?.uploadFile?.[0]?.name ? (
                                <p className="uploaded-file">
                                  {userInputs?.uploadFile?.[0]?.name}
                                </p>
                              ) : (
                                <p>Upload your Video file</p>
                              )}
                            </div>
                          </div>
                          {error?.uploadVideo ? (
                            <div className="login-validation-upload">
                              {error?.uploadVideo}
                            </div>
                          ) : null}
                        </div>
                      ) : // ePrint == "eBook" ? (

                      userInputs.docintelFormat == "ebook" || userInputs.docintelFormat == "ebookVideo" ? (
                        chapter.map((val, i) => {
                          return (
                            <>
                              <div className="form-group val chapter-title">
                                <div className="ebook-format">

                                {userInputs.docintelFormat == "ebookVideo" ? (<>
                                  <div className="d-flex align-items-center justify-content-start w-100 mb-3">
                                    <label htmlFor="">
                                      {localStorage.getItem("user_id") !=
                                        "56Ek4feL/1A8mZgIKQWEqg==" && localStorage.getItem("user_id") != "sNl1hra39QmFk9HwvXETJA=="
                                        ? "Chapter "
                                        : "File "}
                                      {i + 1} format
                                    </label>
                                    <div className="switch">
                                      <label className="switch-light">
                                        <input
                                          type="checkbox"
                                          // checked={val?.type == "video" ? true : false}
                                          // onClick={(e) => onSelectType(e, i, e.target.checked ? 'video' : 'pdf')}
                                          checked={val?.type == "video" ? true : false}
                                          onChange={(e) => {
                                            onChapterFormatChange(e.target?.checked, i);
                                          }}
                                        />
                                        <span>
                                          <span className="switch-btn active">PDF</span>
                                          <span className="switch-btn">Video</span>
                                        </span>
                                        <a className="btn"></a>
                                      </label>
                                    </div>
                                      {val?.type == "video" ?
                                        <fieldset id="group2">
                                          :
                                          <div className="radio-selection">
                                            <input
                                              type="radio"
                                              name={`video-${i}`}
                                              id={`file-existing-${i}`}
                                              checked={val?.videoType === "existing" || !val?.videoType}
                                              onChange={(e) => onSelectVideoType(e, i, 'existing')}
                                            />
                                            <label htmlFor={`file-existing-${i}`}>
                                              <span>Existing video</span>
                                            </label>
                                            <input
                                              type="radio"
                                              name={`video-${i}`}
                                              id={`file-new-${i}`}
                                              checked={val?.videoType === "new"}
                                              onChange={(e) => onSelectVideoType(e, i, 'new')}
                                            />
                                            <label htmlFor={`file-new-${i}`}>
                                              <span>Upload new</span>
                                            </label>
                                          </div></fieldset> : null}
                                    </div>
                                    </>) : null
                                }

                                  <label htmlFor="">
                                    {localStorage.getItem("user_id") !=
                                    "56Ek4feL/1A8mZgIKQWEqg==" && localStorage.getItem("user_id") != "sNl1hra39QmFk9HwvXETJA=="
                                      ? "Chapter "
                                      : "File "}{" "}
                                    {i + 1} title<span>*</span>
                                  </label>

                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => onChapterTitleChange(e, i)}
                                    value={val.chapterTitle}
                                  />
                                  {
                                   userInputs.docintelFormat == "ebookVideo" ?
                                   <>
                                    <div className="upload-file-box">
                                      <div className="box">
                                        {/* <div className="d-flex">
                                          {
                                            // typeof val?.type == 'undefined' ? 
                                              // <>
                                              //   <img src={path_image + "video-img.png"} alt="" onClick={(e) => onSelectType(e, i,'video')}/>
                                              //   <img src={path_image + "spc-img.png"} alt="" onClick={(e) => onSelectType(e, i,'pdf')}/>
                                              // </>
                                            //  :
                                            //  null
                                             
                                          }
                                        </div> */}
                                        {
                                          /*
                                          val?.type == 'video' ?
                                          <img src={path_image + "video-img.png"} alt="" onClick={(e) => onSelectType(e, i,'video')}/>
                                          :val?.type == 'pdf' ? 
                                          <img src={path_image + "spc-img.png"} alt="" onClick={(e) => onSelectType(e, i,'pdf')}/>
                                          : null
                                          */
                                        }
                                        
                                        {
                                          val?.type == 'video' ? 
                                          <>
                                            {/* {
                                              typeof val?.videoType == 'undefined'&& !val.uploadFile ? 
                                              <div className="d-flex">
                                                <p className="upload_new" onClick={(e) => onSelectVideoType(e, i,'new')}>Upload New Video</p>
                                                <p className="select_existing" onClick={(e) => onSelectVideoType(e, i,'existing')}>Select existing Video</p>
                                              </div>
                                              : null
                                            } */}
                                            
                                            {
                                              val?.videoType == 'new' || (val?.videoType == 'new' && val.uploadFile)?
                                              <>
                                                <input
                                                  type="file"
                                                  name={`file-${i}`}
                                                  id={`file-${i}`}
                                                  className={
                                                    error?.chapter?.[i]
                                                      ? "inputfile inputfile-6 error"
                                                      : "inputfile inputfile-6"
                                                  }
                                                  accept="video/mp4"
                                                  onChange={(e) =>
                                                    handleOnEbookChange(e, i)
                                                  }
                                                />
                                                <label htmlFor={`file-${i}`}>
                                                  <span>Choose Your File</span>
                                                </label>

                                                        <p>
                                                          {val.uploadFile === "" ? (
                                                            <>
                                                              Upload your Video file
                                                              <span className="video-format">(Only <b>mp4</b> format is allowed)</span>
                                                            </>
                                                          ) : (
                                                            <span className="uploaded-file">
                                                              {/* {val.uploadFile} */}
                                                              {val.uploadNewFile? val.uploadNewFile : '' }
                                                            </span>
                                                          )}
                                                        </p>


                                              </>
                                              : val?.videoType == 'existing'  || (val?.videoType == 'existing' && val.uploadFile) ? 
                                              <>
                                                <Select
                                                  className={
                                                    error?.docintelFormat
                                                      ? "dropdown-basic-button split-button-dropup error"
                                                      : "dropdown-basic-button split-button-dropup"
                                                  }
                                                  onChange={(event) =>
                                                    handleVideoChange(event?.value,i)
                                                  }
                                                  options={getVideoArticle}
                                                  isClearable
                                                  placeholder="Select video"
                                                  value={getVideoArticle.find(option => option.value === val.selectedVideo) || null}
                                                />
                                              </>
                                              : null
                                            }
                                          </>
                                          : 
                                            val?.type == 'pdf' ? 
                                            <>
                                              <input
                                                type="file"
                                                name={`file-${i}`}
                                                id={`file-${i}`}
                                                className={
                                                  error?.chapter?.[i]
                                                    ? "inputfile inputfile-6 error"
                                                    : "inputfile inputfile-6"
                                                }
                                                accept="application/pdf"
                                                onChange={(e) =>
                                                  handleOnEbookChange(e, i)
                                                }
                                              />
                                              <label htmlFor={`file-${i}`}>
                                                <span>Choose Your File</span>
                                              </label>

                                              <p>
                                                {val.uploadFile == "" ? (
                                                  "Upload your PDF file"
                                                ) : (
                                                  <span className="uploaded-file">
                                                    {val.uploadNewFile? val.uploadNewFile : '' }
                                                  </span>
                                                )}
                                              </p>
                                            </>
                                          : null
                                        }
                                      </div>

                                       
                                    </div>
                                        {val?.videoType == 'new' || (val?.videoType == 'new' && val.uploadFile) ? 
                                     <div className="upload-file-box">
                                     <div className="box">
                                          <input
                                            type="file"
                                            name={`file-thumb-${i}`}
                                            id={`file-thumb-${i}`}
                                            className="inputfile inputfile-5"
                                            accept="image/png, image/jpeg"
                                            onChange={(e) => handleOnVideoThumbChange(e, i)}
                                          />
                                          <label htmlFor={`file-thumb-${i}`}>
                                            <span>Choose Your Image</span>
                                          </label>
                                              <p>
                                                {val.videoThumb == "" ? (
                                                  "Upload chapter thumbnail"
                                                ) : (
                                                  <span className="uploaded-file">
                                                    {/* {val.videoThumb} */}
                                                    {val.videoNewThumb? val.videoNewThumb : '' }
                                                  </span>
                                                )}
                                              </p>
                                        </div>
                                        </div>: ''}
                                        </>
                                  :
                                  <div className="upload-file-box">
                                    <div className="box">
                                      <input
                                        type="file"
                                        name={`file-${i}`}
                                        id={`file-${i}`}
                                        className={
                                          error?.chapter?.[i]
                                            ? "inputfile inputfile-6 error"
                                            : "inputfile inputfile-6"
                                        }
                                        accept="application/pdf"
                                        onChange={(e) =>
                                          handleOnEbookChange(e, i)
                                        }
                                      />
                                      <label htmlFor={`file-${i}`}>
                                        <span>Change Your File</span>
                                      </label>

                                      <p>
                                        {val.uploadFile == "" ? (
                                          "Upload your PDF file"
                                        ) : (
                                          <p className="uploaded-file">
                                            {val.uploadFile}
                                          </p>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  }
                                </div>
                                <div className="chapter-btn-wrapper">
                                  {chapter.length - 1 == i ? (
                                    <Button
                                      className="btn btn-primary btn-bordered btn-voilet move-draft chappter-add-btn"
                                      onClick={addMoreChClicked}
                                    >
                                      Add Ch +
                                    </Button>
                                  ) : null}

                                  {chapter.length > 1 ? (
                                    <Button
                                      className="dlt_btn"
                                      onClick={() => deleteRecord(i, val?.id)}
                                    >
                                      <img
                                        src={path_image + "delete.svg"}
                                        alt="Delete Row"
                                      />
                                    </Button>
                                  ) : null}
                                </div>
                                {error?.chapter?.[i] ? (
                                  <div className="login-validation-upload">
                                    {error?.chapter?.[i]}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          );
                        })
                      ) : userInputs.docintelFormat == "pdfSpc" ? (
                        pdfSpcData.map((val, i) => {
                          return (
                            <>
                              <div className="form-group val chapter-title pdf-spc">
                                <div className="ebook-format">
                                  <label htmlFor="">
                                    {localStorage.getItem("user_id") !=
                                    "56Ek4feL/1A8mZgIKQWEqg==" && localStorage.getItem("user_id") != "sNl1hra39QmFk9HwvXETJA=="
                                      ? "Chapter "
                                      : "File "}{" "}
                                    title
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => onPdfTitleChange(e, i)}
                                    value={val.chapterTitle}
                                  />
                                  <div className="upload-file-box">
                                    <div className="box">
                                      <input
                                        type="file"
                                        name={`file-${i}`}
                                        id={`file-${i}`}
                                        className={
                                          error?.chapter?.[i]
                                            ? "inputfile inputfile-6 error"
                                            : "inputfile inputfile-6"
                                        }
                                        accept="application/pdf"
                                        onChange={(e) =>
                                          handleOnEbookPdfChange(e, i)
                                        }
                                      />
                                      <label htmlFor={`file-${i}`}>
                                        <span>Change Your File</span>
                                      </label>

                                      <p>
                                        {val.uploadFile == "" ? (
                                          "Upload your PDF file"
                                        ) : (
                                          <p className="uploaded-file">
                                            {val.uploadFile}
                                          </p>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {error?.pdfChapter?.[i] ? (
                                  <div className="login-validation-upload">
                                    {error?.pdfChapter?.[i]}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          );
                        })
                      ) : // <div className="form-group val">
                      //   <label htmlFor="">Upload Ebook</label>
                      //   <div className="upload-file-box">
                      //     <div className="box">
                      //       <input
                      //         type="file"
                      //         name="file-6[]"
                      //         id="file-6"
                      //         className="inputfile inputfile-6"
                      //         accept="application/pdf"
                      //         onChange={(e) => handleEbookChange(e)}
                      //       />
                      //       <label htmlFor="file-6">
                      //         <span>Choose Your File</span>
                      //       </label>
                      //       <p>
                      //         {selectedEbookName == ""
                      //           ? "Upload your Ebook file"
                      //           : selectedEbookName}{" "}
                      //       </p>
                      //     </div>
                      //   </div>
                      //   {error?.pdfFile ? (
                      //     <div className="login-validation-upload">
                      //       {error?.pdfFile}
                      //     </div>
                      //   ) : null}
                      // </div>
                      null}

                      {/* <div className="form-group">
                      <label htmlFor="">Include video</label>
                      <div className="switch">
                        <label className="switch-light">
                          <input
                            type="checkbox"
                            onChange={(e) => includeVideoCheckboxChanged(e)}
                          />
                          <span>
                            <span className="switch-btn active">No</span>
                            <span className="switch-btn">Yes</span>
                          </span>
                          <a className="btn"></a>
                        </label>
                      </div>
                      {checked == false ? (
                        <Button
                          className="btn-bordered btn-voilet"
                          onClick={handleShow}
                        >
                          click to embed your Videos{" "}
                        </Button>
                      ) : (
                        false
                      )}
                    </div> */}

                      {userDetail?.user?.[0]?.flag == 0 &&
                      userDetail?.user?.[0]?.group_id == 3 ? (
                        <>
                          {/*
                            <div className="form-group">
                              <label htmlFor="">Include video</label>
                              <div className="switch">
                                <label className="switch-light">
                                  <input
                                    type="checkbox"
                                    defaultChecked={userInputs?.allow_video}
                                    // onChange={(e) => includeVideoCheckboxChanged(e)}
                                    onChange={(e) => {
                                      handleChange(
                                        e.target?.checked,
                                        "allow_video"
                                      );
                                    }}
                                  />
                                  <span>
                                    <span className="switch-btn active">No</span>
                                    <span className="switch-btn">Yes</span>
                                  </span>
                                  <a className="btn"></a>
                                </label>
                              </div>
                            */}

                          {/* {checked == false ? ( */}
                          {/* <Button
                        className="btn-bordered btn-voilet"
                        onClick={handleShow}
                      >
                        click to embed your Videos{" "}
                      </Button> */}
                          {/* ) : (
                        false
                      )}
                        </div>*/}
                        </>
                      ) : null}
                      <div className="form-group val">
                        <label htmlFor="">Content cover</label>
                        <div className="upload-file-box">
                          <div className="box">
                            <input
                              type="file"
                              name="file-cover[]"
                              id="file-cover"
                              className="inputfile inputfile-5"
                              accept="image/png, image/jpeg"
                              onChange={(e) => handleChange(e, "coverPhoto")}
                            />
                            <label htmlFor="file-cover">
                              <span>Choose Your File</span>
                            </label>
                            {userInputs?.coverPhoto?.[0]?.name ? (
                              <p className="uploaded-file">
                                {userInputs?.coverPhoto?.[0]?.name}
                              </p>
                            ) : (
                              <p>
                                Uplode an alternative cover <br />
                                <span>
                                  <i>Allowed formats: PNG,JPEG</i>
                                </span>
                                <br />
                                <span>(Recommended size 88 X 124)</span>
                              </p>
                            )}
                            {/* <p>
                            Upload your cover image
                            <br />
                            <span>(Recommended size 00 X 00)</span>
                          </p> */}
                          </div>
                        </div>
                        {/* {error?.image ? (
                        <div className="login-validation-upload">
                          {error?.image}
                        </div>
                      ) : null} */}
                      </div>
                    </Col>

                    {localStorage.getItem("user_id") !=
                    "56Ek4feL/1A8mZgIKQWEqg==" && localStorage.getItem("user_id") != "sNl1hra39QmFk9HwvXETJA==" ? (
                      <Col
                        className="d-flex justify-content-end align-items-start right-change"
                        md={6}
                      >
                        <div className="form-group justify-content-end">
                          <label htmlFor="">
                            Production notes for Docintel team
                          </label>
                          <textarea
                            className="form-control"
                            id="formControlTextarea"
                            rows="5"
                            defaultValue={userInputs?.productionNotes}
                            onChange={(e) =>
                              handleChange(e?.target.value, "productionNotes")
                            }
                            placeholder="Please type your notes here..."
                          ></textarea>
                        </div>
                      </Col>
                    ) : null}

                    {(ebookFile?.length &&
                      userInputs.docintelFormat=="ebook") ||
                    (["ebook", "pdf", "pdfSpc"].includes(
                      userInputs.docintelFormat
                    ))||
                    (ebookFile?.length &&userInputs.docintelFormat=="ebookVideo"&&chapter.some((element)=>element?.type=="pdf"))
                     && (localStorage.getItem("user_id") ==
                        "rjiGlqA9DXJVH7bDDTX0Lg==" || localStorage.getItem("user_id") ==
                        "iSnEsKu5gB/DRlycxB6G4g==" || localStorage.getItem("user_id") ==
                        "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") ? (
                      <>
                        <div className="form-group">
                          <label htmlFor="">Include video</label>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                defaultChecked={
                                  userInputs?.allowVideo ? true : false
                                }
                                checked={userInputs?.allow_video ? true : false}
                                onChange={(e) => {
                                  handleChange(
                                    e.target?.checked,
                                    "allow_video"
                                  );
                                }}
                              />
                              <span>
                                <span
                                  className={`switch-btn ${
                                    userInputs?.allow_video == 0
                                      ? " Active"
                                      : ""
                                  }`}
                                >
                                  No
                                </span>
                                <span
                                  className={`switch-btn ${
                                    userInputs?.allow_video == 1
                                      ? " Active"
                                      : ""
                                  }`}
                                >
                                  Yes
                                </span>
                              </span>
                              <a className="btn"></a>
                            </label>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </Row>
                </div>
              </div>
            </Row>
          </div>
        ) : null}
      </Col>
      <Modal className="pdf-video-link" show={show} onHide={handleClose}>
        <Modal.Header>
          <div className="form_action embedding-video">
            <div className="side-step-text first-step">
              <div className="embedded-video-step">
                <h2>Step1</h2>
              </div>
              <p>Select the chapter </p>
              <Form.Group className="formgroup">
                <Form.Label>Chapters</Form.Label>
                {/* <ReactSelect
                  placeholder="Select your chapter"
                  options={types}
                  className="dropdown-basic-button split-button-dropup"
                  isClearable
                /> */}
                <DropdownButton
                  className="dropdown-basic-button split-button-dropup "
                  title={
                    chapterSelect != "" ? chapterSelect : "Select your chapter"
                  }
                  onSelect={(event) => onChapterSelect(event)}
                >
                  <div className="scroll_div">
                    <Dropdown.Item
                      eventKey="Chapter 1"
                      className={chapterSelect == "Chapter 1" ? "active" : ""}
                    >
                      Chapter 1
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="Chapter 2"
                      className={chapterSelect == "Chapter 2" ? "active" : ""}
                    >
                      Chapter 2
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="Chapter 3"
                      className={chapterSelect == "Chapter 3" ? "active" : ""}
                    >
                      Chapter 3
                    </Dropdown.Item>
                  </div>
                </DropdownButton>
              </Form.Group>
            </div>
            <div className="side-step-text second-step">
              <div className="embedded-video-step">
                <h2>Step2</h2>
              </div>
              <p>
                Select the video and highlight the area you want to embed the
                video in{" "}
              </p>
              <Form.Group className="formgroup">
                <Form.Label>
                  Videos <span>*</span>
                </Form.Label>
                {/* <ReactSelect
                  placeholder="Select your chapter"
                  className="dropdown-basic-button split-button-dropup"
                  isClearable
                /> */}

                <DropdownButton
                  className="dropdown-basic-button split-button-dropup "
                  title={videoSelect != "" ? videoSelect : "Select your video"}
                  onSelect={(event) => onVideoSelect(event)}
                >
                  <div className="scroll_div">
                    <Dropdown.Item
                      eventKey="Video 1"
                      className={videoSelect == "Video 1" ? "active" : ""}
                    >
                      Video 1
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="Video 2"
                      className={videoSelect == "Video 2" ? "active" : ""}
                    >
                      Video 2
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="Video 3"
                      className={videoSelect == "Video 3" ? "active" : ""}
                    >
                      Video 3
                    </Dropdown.Item>
                  </div>
                </DropdownButton>

                <div className="upload-file-box">
                  {/* <input
                    type="file"
                    name="file-10[]"
                    id="file-10"
                    className="inputfile inputfile-6"
                    accept=".mp4"
                  />
                  <label htmlFor="file-10">
                    <span>Upload new Video +</span>
                  </label> */}
                  <Button
                    className="btn-filled"
                    onClick={onUploadNewVideoClicked}
                  >
                    Upload new Video +
                  </Button>
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body-content">
            <img src={path_image + "pdf-dummy.png"} alt="Close-filter" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-bordered"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className="btn-filled"
            variant="primary"
            // onClick={handleClose}
            onClick={() => navigate("/edit-Consent-Options")}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal id="tagsModal" show={isOpen}>
        <Modal.Header>
          <h5 className="modal-title" id="staticBackdropLabel">
            Add Topic
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={closeModal}
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="select-tags">
            <h6>Select Topic :</h6>
            <div className="tag-lists">
              <div className="tag-lists-view">
                {Object.values(allTags).map((data) => {
                  return (
                    <>
                      <div onClick={(event) => tagClicked(data)}>{data} </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="selected-tags">
            <h6>
              Selected Topic <span>| {tagClickedFirst.length}</span>
            </h6>

            <div className="total-selected">
              {tagClickedFirst.map((data, index) => {
                return (
                  <>
                    <div className="tag-cross">
                      {data.innerHTML || data}
                      <img
                        src={path_image + "filter-close.svg"}
                        alt="Close-filter"
                        onClick={() => removeTagFinal(index, "remove")}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <form>
            <div className="form-group">
              <label htmlFor="new-tag">New Tag</label>
              <input
                type="text"
                className="form-control"
                id="new-tag"
                value={newTag}
                onChange={(e) => newTagChanged(e)}
              />

              <button
                onClick={addTag}
                type="button"
                className="btn btn-primary add btn-bordered"
              >
                Add
              </button>
            </div>
          </form>
          <button
            type="button"
            className="btn btn-primary save btn-filled"
            onClick={saveButtonClicked}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={uploadNewVideo} className="send-confirm" id="download-qr">
        <Modal.Header>
          <h5 className="modal-title" id="staticBackdropLabel">
            Change Embedded Video
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => {
              setUploadNewVideo(false);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="">Video</label>
            <DropdownButton
              className="dropdown-basic-button split-button-dropup "
              title={
                changeEmbeddedVideo != ""
                  ? changeEmbeddedVideo
                  : "Select your video"
              }
              onSelect={(event) => onChangeEmbeddedVideo(event)}
            >
              <div className="scroll_div">
                <Dropdown.Item
                  eventKey="Change Video 1"
                  className={
                    changeEmbeddedVideo == "Change Video 1" ? "active" : ""
                  }
                >
                  Change Video 1
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="Change Video 2"
                  className={
                    changeEmbeddedVideo == "Change Video 2" ? "active" : ""
                  }
                >
                  Change Video 2
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="Change Video 3"
                  className={
                    changeEmbeddedVideo == "Change Video 3" ? "active" : ""
                  }
                >
                  Change Video 3
                </Dropdown.Item>
              </div>
            </DropdownButton>
          </div>
        </Modal.Body>

        <div className="modal-footer">
          <button
            type="button"
            disabled={changeEmbeddedVideo == "" ? true : false}
            className="btn btn-primary save btn-filled"
            onClick={() => setUploadNewVideo(false)}
          >
            Apply
          </button>
        </div>
      </Modal>
      <CommonModel
        show={commanShow}
        onClose={setCommanShow}
        heading={"Add New Product"}
        data={product}
        footerButton={"Add"}
        handleChange={handleModelFun}
        handleSubmit={handleSubmitModelFun}
        // inputValue
      />
    </>
  );
};
export default EditLibrary;
