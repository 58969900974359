import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Dropdown } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import SimpleReactValidator from "simple-react-validator";
import { loader } from "../../../loader";
import EditCountry from "../../CommonComponent/EditCountry";
import EditContactType from "../../CommonComponent/EditContactType";

import { toast } from "react-toastify";

import { connect } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { popup_alert } from "../../../popup_alert";
import DropdownButton from "react-bootstrap/DropdownButton";
import Select, { createFilter } from "react-select";
import makeAnimated from "react-select/animated";
const ViewTable = (props) => {
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const filterConfig = {
    matchFrom: "start",
  };
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED_DESIGN;
  //let path = process.env.REACT_APP_ASSETS_PATH_INFORMED_DESIGN;
  //let validator = new SimpleReactValidator();
  const [userId, setUserId] = useState(localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA=="
    ? "sNl1hra39QmFk9HwvXETJA==" : "56Ek4feL/1A8mZgIKQWEqg==");
  const [instituions, setInstituions] = useState([]);
  const [nonIrtInstitutionType, setNonIrtInstitutionType] = useState([])
  const [irtInstitutionType, setIrtInstitutionType] = useState([])

  const [editable, setEditable] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [addFileReRender, setAddFileReRender] = useState(0);
  const [validator] = React.useState(new SimpleReactValidator());
  const [validator2] = React.useState(new SimpleReactValidator());
  const [validator3] = React.useState(new SimpleReactValidator());
  const [manualReRender, setManualReRender] = useState(0);
  const [update, setUpdate] = useState(0);
  const [siteNameAll, setSiteNameAll] = useState([]);
  const [siteStreetAll, setSiteStreetAll] = useState([]);
  const [siteCityAll, setSiteCityAll] = useState([]);
  const [saveOpen, setSaveOpen] = useState(false);
  const [siteNumber, setSiteNumber] = useState("");
  const [data, setData] = useState(0);
  const [fileValidationMessage, setFileValidationMeassage] = useState(0);
  const [emailData, setEmailData] = useState("");
  const [search, setSearch] = useState("");
  const [showLessInfo, setShowLessInfo] = useState(true);
  const [deleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [showReaders, setShowSaveReader] = useState(false);
  const [save, setSave] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(0);
  const [name_edits, setNameEdit] = useState("");
  const [country_edits, setCountryEdit] = useState("");
  const [email_edits, setEmailEdit] = useState("");

  const [addNewData, setAddNewData] = useState(0);
  const [siteNumberAll, setSiteNumberAll] = useState([]);

  const [profile_user_id, setProfileUserId] = useState();

  const [reRenders, setReRenders] = useState(0);

  const [validator3Counter, setValidator3Counter] = useState(0);
  const [validationError, setValidationError] = useState({});

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [activeManual, setActiveManual] = useState("active");
  const [sitePostalCodeAll, setSitePostCodeAll] = useState([]);
  const [newData, setNewData] = useState([]);
  const [showLessUpdate, setShowLessUpdate] = useState(0);
  const [activeExcel, setActiveExcel] = useState("");
  const [editableData, setEditableData] = useState([]);
  const [siteIrtAll, setSiteIrtAll] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const animatedComponents = makeAnimated();
  const [forceRender, setForceRender] = useState(false);
  const [sortBy, setSortBy] = useState('first_name'); // Initial sort key
  const [sortOrder, setSortOrder] = useState('asc');
  let file_name = useRef("");

  useEffect(() => {
    setUpdatedData(props.data);

    if (typeof props.listId != "undefined" && props.listId != "") {
      setListId(props.listId);
    }

    if (
      typeof props.smartListName != "undefined" &&
      props.smartListName != ""
    ) {
      setListName(props.smartListName);
    }
  }, []);
  useEffect(() => {
    setEditList(props.data);
  }, [props.data?.length]);

  useEffect(() => {
    if (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") {
      axiosFun();
    }
    const getalCountry = async () => {
      let body = {
        user_id: localStorage.getItem("user_id"),
      };
      await axios
        .post(`distributes/filters_list`, body)
        .then((res) => {
          if (res.data.status_code == 200) {
            let user_type;
            let sub_role;
            let blind_type;
            let arrUserType;
            let arrSubRole;
            let arrBlindType;
            let site_number;
            let arrSiteNumber;
            let country = res.data.response.data.country;
            let site_name;
            let arrSiteName;
            let site_street;
            let arrSiteStreet;
            let site_postcode;
            let site_city;
            let arrSiteCity;
            let arrSitePostCode;
            let arrSiteIrt;
            let irt_user_type;
            let arrIrtUserType = [];
            let institutions;
            let arrinstitutions = [];
            let nonIrtInstitution;
            let arrNonIrtInstitution = []
            let irtInstitution;
            let arrIrtInstitution = []

            let arr = [];

            if (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") {
              user_type = res.data.response.data.investigator_type;
              sub_role = res.data.response.data.sub_role;
              blind_type = res.data.response.data.blind_type;
              site_number = res.data.response.data.site_number;
              site_name = res.data.response.data.site_name;
              site_street = res.data.response.data.site_street;
              site_postcode = res.data.response.data.site_post_code;
              site_city = res.data.response.data.site_city;
              irt_user_type = res?.data?.response?.data?.irt_inverstigator_type;
              institutions = res?.data?.response?.data?.institution_type;
              nonIrtInstitution = res?.data?.response?.data?.non_mandatory_institution_type
              irtInstitution = res?.data?.response?.data?.irt_institution_type

              arrUserType = [];
              arrSubRole = [];
              arrBlindType = [];
              arrSiteNumber = [];
              arrSiteName = [];
              arrSiteStreet = [];
              arrSitePostCode = [];
              arrSiteCity = [];
              arrSiteIrt = [
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ];
            }

            //  const data = Object.assign({}, res.data.response.data.blind_type);

            Object.entries(country).map(([index, item]) => {
              let label = item;
              if (index == "B&H") {
                label = "Bosnia and Herzegovina";
              }
              arr.push({
                value: item,
                label: label,
              });
            });

            if (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") {
              Object.entries(site_number).map(([index, item]) => {
                let label = item;

                arrSiteNumber.push({
                  value: item,
                  label: label,
                });
              });

              Object.entries(site_street).map(([index, item]) => {
                let label = item;

                arrSiteStreet.push({
                  value: item,
                  label: label,
                });
              });

              Object.entries(site_city).map(([index, item]) => {
                let label = item;

                arrSiteCity.push({
                  value: item,
                  label: label,
                });
              });

              Object.entries(site_postcode).map(([index, item]) => {
                let label = item;

                arrSitePostCode.push({
                  value: item,
                  label: label,
                });
              });

              Object.entries(site_name).map(([index, item]) => {
                let label = item;

                arrSiteName.push({
                  value: item,
                  label: label,
                });
              });

              Object.entries(user_type).map(([index, item]) => {
                let label = item;

                arrUserType.push({
                  value: item,
                  label: label,
                });
              });

              Object.entries(sub_role).map(([index, item]) => {
                let label = item;
                arrSubRole.push({
                  value: item,
                  label: label,
                });
              });
              Object.entries(blind_type).map(([index, item]) => {
                let label = item;

                arrBlindType.push({
                  value: item,
                  label: label,
                });
              });
              Object.entries(irt_user_type)?.map(([item, index]) => {
                arrIrtUserType.push({
                  label: item,
                  value: item,
                });
              });
              Object.entries(institutions)?.map(([item, index]) => {
                arrinstitutions.push({
                  label: item,
                  value: item,
                });
              });
              Object.entries(nonIrtInstitution)?.map(([item, index]) => {
                arrNonIrtInstitution.push({
                  label: item,
                  value: item,
                });
              });
              Object.entries(irtInstitution)?.map(([item, index]) => {
                arrIrtInstitution.push({
                  label: item,
                  value: item,
                });
              });
            }

            setCountryall(arr);
            if (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") {
              setIrtRole(arrIrtUserType);
              setInstituions(arrinstitutions);
              setUserTypeAll(arrUserType);
              setSubUserTypeAll(arrSubRole);
              // setSiteNumberAll(arrSiteNumber);
              // setSiteNameAll(arrSiteName);
              setSiteStreetAll(arrSiteStreet);
              setSitePostCodeAll(arrSitePostCode);
              setSiteCityAll(arrSiteCity);
              setSiteIrtAll(arrSiteIrt);
              setBlindTypeAll(arrBlindType);
              setSiteData(res.data.response.data.site_data);
              setChanges(res.data.response.data);

              setNonIrtInstitutionType(arrNonIrtInstitution)
              setIrtInstitutionType(arrIrtInstitution)
            }

            // setCountryall(res.data.response.data.country);
          }

        })
        .catch((err) => {
          //console.log(err);
        });
    };
    getalCountry();
  }, []);

  const [name, setName] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [company, setCompany] = useState(null);
  const [indication, setIndication] = useState(null);
  const [product, setProduct] = useState(null);
  const [country, setCountry] = useState(null);
  const [email, setEmail] = useState(null);
  const [updateData, setUpdatedData] = useState(null);
  const [totalData, setTotalData] = useState({});
  const [editList, setEditList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [counterFlag, setCounterFlag] = useState(0);
  const [getlistid, setListId] = useState("");
  const [getlistname, setListName] = useState("");
  const [getlistcount, setListCount] = useState("");
  const [change, setChanges] = useState(null);
  const [irtRole, setIrtRole] = useState([]);

  let path = process.env.REACT_APP_ASSETS_PATH_INFORMED_DESIGN;
  const [show, setShow] = useState(false);
  const [hpc, setHpc] = useState([
    {
      firstname: "",
      lastname: "",
      email: "",
      contact_type: "",
      country: "",
      countryIndex: "",
      userType:
        (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
          ? irtRole?.[0]?.value
          : "",
      roleIndex:
        (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") ? 0 : "",
      subUserType: "",
      siteNumber: "",
      subUserTypeIndex: "",
      siteNumberIndex: "",
      blindType: "",
      blindTypeIndex: "",
      sitePostCode: "",
      sitePostCodeIndex: "",
      siteCity: "",
      siteCityIndex: "",
      siteStreet: "",
      siteStreetIndex: "",
      siteName: "",
      siteNameIndex: "",
      siteIrt:
        (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
          ? siteIrtAll?.find((item) => item?.value == "Yes")?.value
          : "",
      siteIrtIndex:
        (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
          ? siteIrtAll?.findIndex((item) => item?.value == "Yes")
          : "",
      institute: localStorage.getItem("user_id") == userId
        ? irtInstitutionType?.[0]?.value
        : "",
      instituteIndex: localStorage.getItem("user_id") == userId ? 0 : "",
      siteNumber: "",
      siteName: ""
    },
  ]);

  const [siteCity, setSiteCity] = useState("");

  const [sitePostCode, setSitePostCode] = useState("");

  const [siteStreet, setSiteStreet] = useState("");

  const [siteName, setSiteName] = useState("");
  const [renderCounterData, setCounterData] = useState([]);

  const [countryall, setCountryall] = useState([]);
  const [irtCountry, setIRTCountry] = useState([]);
  const [userTypeAll, setUserTypeAll] = useState([]);
  const [subUserTypeAll, setSubUserTypeAll] = useState([]);
  const [blindTypeAll, setBlindTypeAll] = useState([]);
  const handleClose = () => {
    setShow(false);
    setCounter([0]);
    setCounterData([]);
  };
  const handleShow = () => {
    setIsOpenAdd(true);
    setHpc([
      {
        firstname: "",
        lastname: "",
        email: "",
        contact_type: "",
        country: "",
        countryIndex: "",
        userType:
          (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
            ? irtRole?.[0]?.value
            : "",
        roleIndex:
          (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
            ? 0
            : "",
        siteIrt:
          (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
            ? siteIrtAll?.find((item) => item?.value == "Yes")?.value
            : "",
        siteIrtIndex:
          (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
            ? siteIrtAll?.findIndex((item) => item?.value == "Yes")
            : "",
        siteDetails: [
          {
            siteNumber: "",
            siteName: "",
            siteStreet: "",
            sitePostCode: "",
            siteCity: "",
          },
        ],
        institute: localStorage.getItem("user_id") == userId
          ? irtInstitutionType?.[0]?.value
          : "",
        instituteIndex: localStorage.getItem("user_id") == userId ? 0 : "",
        siteNumber: "",
        siteName: ""
      },
    ]);
    setActiveManual("active");
    setActiveExcel("");
  };

  const [showUploadMenu, setShowUploadMenu] = useState(false);
  const [render, setReRender] = useState(0);
  const handleCloseUploadMenu = () => setShowUploadMenu(false);
  const handleShowUploadMenu = () => {
    setShowUploadMenu(true);
    setShow(false);
  };

  let combine_data;
  let combine_data_manual;

  const [counter, setCounter] = useState([0]);

  const axiosFun = async () => {
    try {
      const result = await axios.get(`emailapi/get_site?uid=${localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==" ? 2147536982 : 2147501188}`);

      let country = result?.data?.response?.data?.site_country_data;
      let arr = [];
      Object.entries(country).map(([index, item]) => {
        let label = item;
        if (index == "B&H") {
          label = "Bosnia and Herzegovina";
        }
        arr.push({
          value: item,
          label: label,
        });
      });
      setIRTCountry(arr);
    } catch (err) {
      console.log("-err", err);
    }
  };

  const editButtonClicked = () => {
    if (editable == 1) {
      setSaveOpen(false);
    } else {
      setSaveOpen(true);
    }

    let temp_val = 1 - editable;
    setEditable(temp_val);
    setUpdate(update + 1);
  };

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const closeClicked = () => {
    setSaveOpen(false);
    setEditable(0);
    let vr = editList;
    let new_add_arr = newData;
    setEditList([]);
    setNewData([]);
    setTimeout(() => {
      setEditList(vr);
      setNewData(new_add_arr);
      setUpdateCounter(updateCounter + 1);
    }, 50);
  };

  //useEffect(() => {}, [editList]);

  useEffect(() => {
    setEditList(props.data);
  }, [props.api_flag]);

  useEffect(() => {
    setNewData([]);
    const showFileInList = async () => {
      const profile_user_id_array = editList.map((data) => {
        return data.profile_user_id;
      });
      const body = {
        user_list: profile_user_id_array,
        smart_list_id: getlistid,
        user_id: localStorage.getItem("user_id"),
        smart_list_name: getlistname,
        submit_type: props.upload_by_filter,
        new_users_list: [],
        creator_name: props.creatorName,
        ibu: props?.ibu ? props?.ibu : "",
      };

      if (props.upload_by_filter == 1) {
        if (typeof props.filter_payload === "object") {
          Object.assign(body, { filters: props.filter_payload });
        }
      }

      axios.defaults.baseURL = process.env.REACT_APP_API_KEY;
      loader("show");
      await axios
        .post(`distributes/add_update_list`, body)
        .then((res) => {
          loader("hide");

          if (res.data.status_code === 200) {
            popup_alert({
              visible: "show",
              message: "Your changes has been saved <br/> successfully !",
              type: "success",
              redirect: "/SmartList",
            });
          } else {
            popup_alert({
              visible: "show",
              message: res.data.message,
              type: "error",
            });
          }
          //window.location.href = "/SmartList";
        })
        .catch((err) => {
          toast.error("Something went wrong");
          //console.log(err);
        });
    };
    if (addNewData > 0) {
      showFileInList();
    }
  }, [addNewData]);

  const showFileInReadersList = async () => {
    setEditList((oldArray) => [...newData, ...oldArray]);
    setAddNewData(addNewData + 1);
    setShowSaveReader(false);
    // setTimeout(() => {
    //   //console.log(editList);
    // }, 1000);
    // //console.log(editList);

    // if (props.listId) {
  };

  /**
   *
   * @param id - The id of the product
   * @param currentUnitPrice - The current unit price of the product
   */
  const onEdit = ({
    id,
    currentName,
    currentJobTitle,
    currentCompany,
    currentIndication,
    currentProduct,
    currentCountry,
    currentEmail,
  }) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setName(currentName);
    setJobTitle(currentJobTitle);
    setCompany(currentCompany);
    setIndication(currentIndication);
    setProduct(currentProduct);
    setCountry(currentCountry);
    setEmail(currentEmail);
  };

  /**
   *
   * @param id
   * @param newUnitPrice
   */

  /**
   *
   * @param id -The id of the product
   * @param newUnitPrice - The new unit price of the product
   */

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });

    setName(null);
    setJobTitle(null);
    setCompany(null);
    setIndication(null);
    setProduct(null);
    setCountry(null);
    setEmail(null);
  };

  const deleteRecord = (i) => {
    const list = hpc;
    list.splice(i, 1);
    setHpc(list);
    setCounterFlag(counterFlag + 1);
  };

  // const showMoreInfo = (e) => {
  //   e.preventDefault();
  //   setShowLessInfo(!showLessInfo);
  // };

  const showMoreInfo = (e) => {
    e.preventDefault();
    setShowLessInfo(!showLessInfo);

    const selectedHcpList = document.querySelector('.selected-hcp-list');

    if (showLessInfo) {
      selectedHcpList.classList.add('expand');
    } else {
      selectedHcpList.classList.remove('expand');
    }
  };

  const addMoreHcp = (e) => {
    e.preventDefault();
    const status = hpc.map((data) => {
      if (localStorage.getItem("user_id") == userId) {
        if (data.siteIrt == "Yes") {
          if (data.firstname == "" ||
            data.lastname == "" ||
            data.country == "" ||
            data.email == "" ||
            data.institute == "" ||
            typeof data.institute == "undefined" ||
            data?.siteName == "" || data?.siteNumber == "" || data?.userType == "" ||
            typeof data?.userType == "undefined"

          ) {
            return "false";
          } else {
            return "true"
          }
        } else {
          if (data.firstname == "" ||
            data.lastname == "" ||
            data.country == "" ||
            data.email == "" ||
            data.institute == "" ||
            typeof data.institute == "undefined") {
            return "false";
          } else {
            return "true"
          }
        }
      }
      else if (localStorage.getItem("user_id") == "m5JI5zEDY3xHFTZBnSGQZg==") {
        if (data.email == "" || data.country == "") {
          return "false"
        } else {
          return "true"
        }
      }
      else if (data.email == "") {

        return "false";
      } else {
        return "true";
      }
    });
    if (status.every((element) => element == "true")) {
      setHpc([
        ...hpc,
        {
          firstname: "",
          lastname: "",
          email: "",
          contact_type: "",
          country: "",
          countryIndex: "",
          userType:
            (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
              ? irtRole?.[0]?.value
              : "",
          roleIndex:
            (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
              ? 0
              : "",
          subUserType: "",
          subUserTypeIndex: "",
          blindType: "",
          blindTypeIndex: "",
          siteNumber: "",
          siteName: "",
          siteStreet: "",
          sitePostCode: "",
          siteCity: "",
          siteNumberIndex: "",
          siteNameIndex: "",
          siteStreetIndex: "",
          sitePostCodeIndex: "",
          siteCityIndex: "",
          siteIrt:
            (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
              ? siteIrtAll?.find((item) => item?.value == "Yes")?.value
              : "",
          siteIrtIndex:
            (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
              ? siteIrtAll?.findIndex((item) => item?.value == "Yes")
              : "",
          institute: localStorage.getItem("user_id") == userId
            ? irtInstitutionType?.[0]?.value
            : "",
          instituteIndex: localStorage.getItem("user_id") == userId ? 0 : "",
          siteNumber: "",
          siteName: ""
        },
      ]);
    } else if (localStorage.getItem("user_id") == "m5JI5zEDY3xHFTZBnSGQZg==" || localStorage.getItem("user_id") == userId) {
      toast.warning("Please input the required fields");
    }
    else {
      toast.warning("Please input the email atleast");
    }
  };

  const addMoreSite = (i, e) => {
    e.preventDefault();

    let old_hpc = hpc;
    old_hpc[i].siteDetails.push({
      siteNumber: "",
      siteName: "",
      siteStreet: "",
      sitePostCode: "",
      siteCity: "",
    });

    setHpc(old_hpc);
    setUpdate(update + 1);
  };
  const removeSite = (index, i, e) => {
    e.preventDefault();

    let old_hpc = hpc;
    old_hpc[i].siteDetails.splice(index, 1);

    setHpc(old_hpc);
    setUpdate(update + 1);
  };

  const addHcp = () => {
    setActiveExcel("");
    setActiveManual("active");
    setManualReRender(manualReRender + 1);
  };

  const saveEditClicked = async () => {
    setEditable(0);
    if (editableData.length > 0) {
      editableData.map((data) => {
        const name_edit = document.getElementById(
          "field_name" + data.profile_user_id
        ).innerText;
        const country_edit = document.getElementById(
          "field_country" + data.profile_user_id
        ).value;
        const edit_index = document.getElementById(
          "field_index" + data.profile_user_id
        ).value;
        const contact_type_edit =
          (localStorage.getItem("user_id") !== "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
            ? document.getElementById(
              "field_contact_type" + data.profile_user_id
            ).value
            : "";

        let prev_obj = editList.find(
          (x) => x.profile_user_id === data.profile_user_id
        );
        if (typeof prev_obj != "undefined") {
          if (typeof editList[edit_index] != "undefined") {
            editList[edit_index].country = country_edit;
          }
          if (typeof editList[edit_index] != "undefined") {
            editList[edit_index].contact_type = contact_type_edit;
          }
        } else {
          if (typeof newData[edit_index] != "undefined") {
            newData[edit_index].country = country_edit;
          }
          if (typeof newData[edit_index] != "undefined") {
            newData[edit_index].contact_type = contact_type_edit;
          }
        }

        data.country = country_edit;
        data.username = name_edit;
        data.contact_type = contact_type_edit;
      });
    }

    if (editableData.length > 0) {
      const body = {
        user_id: localStorage.getItem("user_id"),
        edit_list_array: editableData,
      };

      axios.defaults.baseURL = process.env.REACT_APP_API_KEY;
      loader("show");
      await axios
        .post(`distributes/update_reders_details`, body)
        .then((res) => {
          loader("hide");
          if (res.data.status_code === 200) {
            toast.success("List updated");
          } else {
            popup_alert({
              visible: "show",
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });

      setSaveOpen(false);
      setEditableData([]);
    } else {
      setSaveOpen(false);
      toast.warning("No row update");
    }
  };

  const updateReaderDetails = async ({
    profile_id,
    newName,
    email,
    jobTitle,
    company,
    country,
    profile_user_id,
  }) => {
    const body = {
      user_id: localStorage.getItem("user_id"),
      profile_user_id: profile_user_id,
      profile_id: profile_id,
      email: email,
      jobTitle: jobTitle,
      company: company,
      country: country,
      username: name,
    };

    loader("show");
    axios.defaults.baseURL = process.env.REACT_APP_API_KEY;
    await axios
      .post(`distributes/update_reders_details`, body)
      .then((res) => {
        onCancel();

        var result = editList.filter((obj) => {
          return obj.profile_user_id === body.profile_user_id;
        });

        if (result) {
          var first_name = body.username.substring(
            0,
            body.username.lastIndexOf(" ") + 1
          );
          var last_name = body.username.substring(
            body.username.lastIndexOf(" ") + 1,
            body.username.length
          );

          result[0].email = body.email;
          result[0].country = body.country;
          result[0].company = body.company;
          result[0].jobTitle = body.jobTitle;
          result[0].first_name = first_name;
          result[0].last_name = last_name;

          const index = editList.findIndex(
            (el) => el.profile_user_id === result.profile_user_id
          );
        }
        setReRender(render + 1);
        loader("hide");
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const onSave = ({
    profile_id,
    newName,
    email,
    jobTitle,
    company,
    country,
    profile_user_id,
  }) => {
    if (validator3.allValid()) {
      updateReaderDetails({
        profile_id,
        newName,
        email,
        jobTitle,
        company,
        country,
        profile_user_id,
      });
    } else {
    }
  };

  const deleteReader = async (profile_user_id) => {
    const body = {
      smart_list_id: getlistid,
      user_id: localStorage.getItem("user_id"),
      profile_user_id: profile_user_id,
    };

    axios.defaults.baseURL = process.env.REACT_APP_API_KEY;
    loader("show");
    await axios
      .post(`distributes/delete_reader`, body)
      .then((res) => {
        loader("hide");
      })
      .catch((err) => {
        //console.log(err);
      });

    const filtered_list_update = updateData.filter((data) => {
      return data.profile_user_id != profile_user_id;
    });

    const filtered_list = editList.filter((data) => {
      return data.profile_user_id != profile_user_id;
    });

    setUpdatedData(filtered_list_update);
    setEditList(filtered_list);
  };

  const editing = (
    profile_id,
    profile_user_id,
    email,
    jobTitle,
    company,
    country,
    names,
    contact_type
  ) => {
    // let ignoreClickOnMeElement = document.getElementById(
    //   "row-selected" + index
    // );

    if (editable != 0) {
      // ignoreClickOnMeElement.addEventListener(
      //   "mouseleave",
      //   async (event) => {
      const name_edit = document.getElementById(
        "field_name" + profile_user_id
      ).innerText;
      const country_edit = document.getElementById(
        "field_country" + profile_user_id
      ).value;
      const contact_type_edit =
        (localStorage.getItem("user_id") !== "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
          ? document.getElementById("field_contact_type" + profile_user_id)
            .value
          : "";

      var arr = [];
      arr.push({
        profile_id: profile_id,
        profile_user_id: profile_user_id,
        email: email,
        jobTitle: jobTitle,
        company: company,
        country: country_edit,
        username: name_edit,
        contact_type: contact_type_edit,
      });

      // if(editableData.length > 0){
      let prev_obj = editableData.find(
        (x) => x.profile_user_id === profile_user_id
      );
      if (typeof prev_obj != "undefined") {
        //update existing
        editableData.map(
          (obj) => arr.find((o) => o.profile_user_id === profile_user_id) || obj
        );
      } else {
        //create new
        setEditableData((oldArray) => [...oldArray, ...arr]);
      }
      // }else{
      //     //create new
      //     setEditableData((oldArray) => [...oldArray, ...arr]);
      // }
      // //console.log(name_edit);
      // //console.log(editableData);
      // },
      // { once: true }
      // );
    }

    // ignoreClickOnMeElement.addEventListener("mouseleave", async (event) => {

    //   //console.log(event);
    //   //console.log(index);

    //   const data = editList.find((x) => x.profile_id === profile_id);
    //   //console.log(data);

    //   if (
    //     data.first_name + " " + data.last_name != name_edit ||
    //     data.email != email_edit ||
    //     data.country != country_edit
    //   ) {
    //     const body = {
    //       user_id: localStorage.getItem("user_id"),
    //       profile_user_id: profile_user_id,
    //       profile_id: profile_id,
    //       email: email_edit,
    //       country: country_edit,
    //       username: name_edit,
    //     };

    //     axios.defaults.baseURL = process.env.REACT_APP_API_KEY;
    //     await axios
    //       .post(`distributes/update_reders_details`, body)
    //       .then((res) => {
    //         //console.log(res);
    //       })
    //       .catch((err) => {
    //         //console.log(err);
    //       });
    //   }
    // });
  };

  const addFile = () => {
    setActiveExcel("active");
    setActiveManual("");
    setAddFileReRender(addFileReRender + 1);
  };

  const onDelete = async ({
    profile_id,
    newName,
    email,
    jobTitle,
    company,
    country,
    profile_user_id,
  }) => {
    if (updateData.length > 1) {
      setIsOpen(true);
      setProfileUserId(profile_user_id);
    } else {
      popup_alert({
        visible: "show",
        message: "Please keep atleast one reader or delete the smart list",
        type: "error",
      });
    }
  };

  const onFirstNameChange = (e, i) => {
    const { value } = e.target;
    const list = [...hpc];
    const name = hpc[i].firstname;
    list[i].firstname = value;
    setHpc(list);
  };

  const onLastNameChange = (e, i) => {
    const { value } = e.target;
    const list = [...hpc];
    const name = hpc[i].lastname;
    list[i].lastname = value;
    setHpc(list);
  };

  const onEmailChange = (e, i) => {
    const { value } = e.target;
    const list = [...hpc];
    const name = hpc[i].email;
    list[i].email = value;
    setHpc(list);
    setEmailData(e.target.value);
  };
  const onSiteNumberChange = (e, i) => {
    if (e == null) {
      const list = [...hpc];
      list[i].siteNumber = "";
      setHpc(list);
    } else {
      let getSiteData = siteData;
      let site_name_value = getSiteData[e.value];
      const value = e.value;
      const list = [...hpc];
      const name = hpc[i].siteNumber;
      list[i].siteNumber = value;
      list[i].siteName = site_name_value;

      let snameindex = siteNameAll.findIndex(
        (x) => x.value === site_name_value
      );
      list[i].siteNameIndex = snameindex;

      let index = siteNumberAll.findIndex((x) => x.value === value);
      list[i].siteNumberIndex = index;
      setHpc(list);
    }
    // e.preventDefault();
    // if (index != 0) {
    //   const { value } = e.target;
    //   const old_hpc = hpc;
    //   old_hpc[i].siteDetails[index].siteNumber = value;

    //   setHpc(old_hpc);
    //   setUpdate(update + 1);
    // } else if (index == 0) {
    //   const { value } = e;
    //   const old_hpc = hpc;
    //   old_hpc[i].siteDetails[index].siteNumber = value;

    //   setHpc(old_hpc);
    //   setUpdate(update + 1);
    // }
  };

  const onSiteNameChange = (e, i) => {
    if (e == null) {
      const list = [...hpc];
      list[i].siteName = "";
      setHpc(list);
    } else {
      const value = e.value;
      let getSiteData = siteData;
      let site_number_value = Object.keys(getSiteData).find(
        (key) => getSiteData[key] === e.value
      );

      const list = [...hpc];
      const name = hpc[i].siteName;
      list[i].siteName = value;
      list[i].siteNumber = site_number_value;

      let snameindex = siteNumberAll.findIndex(
        (x) => x.value === site_number_value
      );
      list[i].siteNumberIndex = snameindex;

      let index = siteNameAll.findIndex((x) => x.value === value);
      list[i].siteNameIndex = index;
      setHpc(list);
    }
  };

  const onSiteStreetChange = (e, i) => {
    if (e == null) {
      const list = [...hpc];
      list[i].siteStreet = "";
      list[i].siteStreet = "";
      setHpc(list);
    } else {
      const value = e.value;
      const list = [...hpc];
      const name = hpc[i].siteStreet;
      list[i].siteStreet = value;

      let index = siteStreetAll.findIndex((x) => x.value === value);
      list[i].siteStreetIndex = index;
      setHpc(list);
    }
  };

  const onSiteIrtChange = (e, i) => {
    if (e == null) {
      const list = [...hpc];
      list[i].siteIrt = "";
      list[i].siteIrt = "";
      list[i].userType = "";
      list[i].roleIndex = "";
      list[i].country = "";
      setHpc(list);
    } else {
      const value = e.value;
      const list = [...hpc];
      const name = hpc[i].siteIrt;
      list[i].siteIrt = value;

      let index = siteIrtAll.findIndex((x) => x.value === value);
      list[i].siteIrtIndex = index;
      list[i].roleIndex = "";
      list[i].country = "";
      list[i].siteNumberIndex = "";
      list[i].siteNameIndex = "";
      list[i].siteName = "";
      list[i].siteNumber = "";
      list[i].userType = value == "Yes" ? irtRole[0] : "Other";
      list[i].roleIndex = value == "Yes" ? 0 : 4;
      list[i].institute = value == "Yes" ? irtInstitutionType?.[0]?.value : "";
      list[i].instituteIndex = value == "Yes" ? 0 : ""
      setHpc(list);
    }
    let arr = [];
    setSiteNumberAll(arr);
    setSiteNameAll(arr);
    setForceRender(!forceRender);
  };

  const onSiteCityChange = (e, i) => {
    if (e == null) {
      const list = [...hpc];
      list[i].siteCity = "";
      list[i].siteCity = "";
      setHpc(list);
    } else {
      const value = e.value;
      const list = [...hpc];
      const name = hpc[i].siteCity;
      list[i].siteCity = value;

      let index = siteCityAll.findIndex((x) => x.value === value);
      list[i].siteCityIndex = index;
      setHpc(list);
    }
  };

  const onSitePostCode = (e, i) => {
    // if (e == null) {
    //   const list = [...hpc];
    //   list[i].sitePostCode = "";
    //   list[i].sitePostCode = "";
    //   setHpc(list);
    // } else {
    const value = e.value;
    const list = [...hpc];
    const name = hpc[i].sitePostCode;
    list[i].sitePostCode = value;

    let index = sitePostalCodeAll.findIndex((x) => x.value === value);
    list[i].sitePostCodeIndex = index;
    setHpc(list);
  };

  const onContactTypeChange = (e, i) => {
    const value = e;
    const list = [...hpc];
    const name = hpc[i].contact_type;
    list[i].contact_type = value;
    setHpc(list);
  };

  const onCountryChange = (e, i) => {
    if (e == null) {
      const list = [...hpc];
      list[i].country = "";
      list[i].countryIndex = "";
      setHpc(list);
    } else {
      if (localStorage.getItem("user_id") === "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") {
        let consetValue = e.value;
        if (e.value == "B&H") {
          consetValue = "Bosnia and Herzegovina";
        }

        const matchingKeys = Object.entries(change.site_country_data)
          .filter(([key, value]) => value === consetValue)
          .map(([key, value]) => key);

        const filteredSiteNames = matchingKeys.map((key) => ({
          label: change.site_data[key],
          value: change.site_data[key],
        }));
        const siteNumbers = matchingKeys.map((key) => ({
          label: key,
          value: key,
        }));

        setSiteNumberAll(siteNumbers);
        setSiteNameAll(filteredSiteNames);
      }

      const value = e.value;
      const list = [...hpc];
      const name = hpc[i].country;
      list[i].country = value;

      let index = countryall.findIndex((x) => x.value === value);
      list[i].countryIndex = index;
      list[i].siteNumberIndex = "";
      list[i].siteNameIndex = "";
      list[i].siteName = "";
      list[i].siteNumber = "";
      setHpc(list);
    }
  };

  const onUserTypeChange = (e, i) => {
    if (e == null) {
      const list = [...hpc];
      list[i].userType = "";
      list[i].roleIndex = "";
      setHpc(list);
    } else {
      const value = e.value;
      const list = [...hpc];
      const name = hpc[i].userType;
      list[i].userType = value;

      let index = countryall.findIndex((x) => x.value === value);
      list[i].roleIndex = index;
      setHpc(list);
    }
  };
  const onInstitutionChange = (e, i) => {
    if (e == null) {
      const list = [...hpc];
      list[i].institute = "";
      list[i].institute = "";
      setHpc(list);
    } else {
      const value = e.value;

      const list = [...hpc];
      if (value == "Study site") {
        list[i].siteIrtIndex = 0;
        list[i].siteIrt = "Yes";
        list[i].userType = irtRole[0]?.value;
        list[i].roleIndex = 0;
        let index = irtInstitutionType.findIndex((x) => x.value === value);
        list[i].instituteIndex = index;
      } else {
        list[i].siteIrtIndex = 1;
        list[i].siteIrt = "No";
        list[i].userType = "Other";
        list[i].roleIndex = 4;
        let index = nonIrtInstitutionType.findIndex((x) => x.value === value);
        list[i].instituteIndex = index;
      }
      list[i].siteNumberIndex = "";
      list[i].siteNameIndex = "";
      list[i].siteName = "";
      list[i].siteNumber = "";
      list[i].country = "";
      const name = hpc[i].institute;
      list[i].institute = value;

      // let index = instituions.findIndex((x) => x.value === value);
      // list[i].instituteIndex = index;

      if (value != "Study site") {
        let arr = [];
        setSiteNumberAll(arr);
        setSiteNameAll(arr);
        setForceRender(!forceRender);
      }
      setHpc(list);
    }
  };
  const onSubUserTypeChange = (e, i) => {
    if (e == null) {
      const list = [...hpc];
      list[i].subUserType = "";
      list[i].subUserType = "";
      setHpc(list);
    } else {
      const value = e.value;
      const list = [...hpc];
      const name = hpc[i].subUserType;
      list[i].subUserType = value;

      let index = subUserTypeAll.findIndex((x) => x.value === value);
      list[i].subUserTypeIndex = index;
      setHpc(list);
    }
  };

  const onBlindTypeChange = (e, i) => {
    if (e == null) {
      const list = [...hpc];
      list[i].blindType = "";
      list[i].blindType = "";
      setHpc(list);
    } else {
      const value = e.value;
      const list = [...hpc];
      const name = hpc[i].blindType;
      list[i].blindType = value;

      let index = blindTypeAll.findIndex((x) => x.value === value);
      list[i].blindTypeIndex = index;
      setHpc(list);
    }
  };

  const backClicked = () => {
    window.history.go(-1);
    //props.api_flag(0);
  };

  const searchChange = (e) => {
    setSearch(e.target.value);

    if (e.target.value === "") {
      setEditList(updateData);
    }
  };

  const submitHandler = (event) => {
    let r_table = [];
    updateData.find(function (item) {
      if (
        item.first_name.includes(search) ||
        item.last_name.includes(search) ||
        item.email.includes(search)
      ) {
        r_table.push(item);
      }
    });
    if (r_table.length > 0) {
      setEditList(r_table);
    } else {
      popup_alert({
        visible: "show",
        message: "Data not found",
        type: "error",
      });
    }
    event.preventDefault();
    return false;
  };

  const saveClicked = async (e) => {
    //  setIsOpenAdd(false);

    if (activeManual == "active") {
      const body_data = hpc.map((data, index) => {
        return {
          first_name: data.firstname,
          last_name: data.lastname,
          email: data.email,
          country: data.country,
          contact_type: data.contact_type,
          investigator_type: data.userType ? data.userType : "",
          sub_roll: data.subUserType ? data.subUserType : "",
          blind_type: data.blindType ? data.blindType : "",
          siteNumber: data.siteNumber ? data.siteNumber : "",
          siteName: data.siteName ? data.siteName : "",
          siteStreet: data.siteStreet ? data.siteStreet : "",
          sitePostalCode: data.sitePostCode ? data.sitePostCode : "",
          siteCity: data.siteCity ? data.siteCity : "",
          institution_type: data.institute ? data.institute : "",
          siteIrt:
            data.siteIrt == "Yes" ? 1 : data.siteIrt == "Training" ? 2 : 0,
        };
      });

      var pattern = "^w+@[a-zA-Z_]+?.[a-zA-Z]{2,3}$";

      const body = {
        data: body_data,
        user_id: localStorage.getItem("user_id"),
        smart_list_id: getlistid,
      };
      const status = body.data.map((data, index) => {
        if (
          data.first_name == "" &&
          (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
        ) {
          setValidationError({
            firstName: "Please enter the first name",
            index: index,
          });
          return

        } else if (
          data.last_name == "" &&
          (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
        ) {
          setValidationError({
            lastName: "Please enter the last name",
            index: index,
          });
          return;
        } else if (data.email == "") {
          setValidationError({
            email: "Please enter the email",
            index: index,
          });
          return;
        } else if (
          data.institution_type == "" &&
          (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
        ) {
          setValidationError({
            institutionType: "Please select the Institution type",
            index: index,
          });
          return;
        } else if (
          data.country == "" &&
          (localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==" ||
            localStorage.getItem("user_id") == "m5JI5zEDY3xHFTZBnSGQZg==")
        ) {
          setValidationError({
            country: "Please select the country",
            index: index,
          });
          return;
        } else if (data.siteNumber == "" && data.siteIrt == 1 && localStorage.getItem("user_id") == userId) {
          setValidationError({
            siteNumber: "Please select site number",
            index: index,
          });
          return;
        } else if (data.siteName == "" && data.siteIrt == 1 && localStorage.getItem("user_id") == userId) {
          setValidationError({
            siteName: "Please select site name",
            index: index,
          });
          return;
        }
        else if (data.email != "") {
          let email = data.email;
          let useremail = email.trim();
          // var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          // if (!regex.test(String(useremail).toLowerCase())) {
          var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          if (regex.test(String(useremail).toLowerCase())) {
            let prev_obj = editList.find((x) => x.email === useremail);
            let prev_obj_new = newData.find((x) => x.email === useremail);
            if (typeof prev_obj != "undefined" || typeof prev_obj_new != "undefined") {
              setValidationError({
                email: "User with same email already added in list.",
                index: index,
              });
              return;
            } else {
              return "true";
            }
          } else {
            setValidationError({
              email: "Email format is not valid",
              index: index,
            });
            return;
          }
        } else {
          return "true";
        }
      });

      status.sort();
      if (status.every((element) => element == "true")) {
        loader("show");
        axios.defaults.baseURL = process.env.REACT_APP_API_KEY;

        await axios
          .post(`distributes/add_new_readers_in_list`, body)
          .then((res) => {
            if (res.data.status_code === 200) {

              let old_data = editList;
              let new_data = res.data.response.data;
              setNewData((oldArray) => [...new_data, ...oldArray]);

              combine_data_manual = [...new_data, ...old_data];

              setEditList(old_data);

              setIsOpen(false);
              setShowSaveReader(true);
              setIsOpenAdd(false);
            } else {
              toast.warning(res.data.message);
              loader("hide");
            }
            loader("hide");
          })
          .catch((err) => {
            toast.error("Something went wrong");
            loader("hide");
          });
      }
    } else {
      let formData = new FormData();
      let user_id = localStorage.getItem("user_id");
      formData.append("user_id", user_id);
      formData.append("smart_list_id", getlistid);
      formData.append("reader_file", selectedFile);

      if (selectedFile) {
        axios.defaults.baseURL = process.env.REACT_APP_API_KEY;
        loader("show");
        await axios
          .post(`distributes/update_reader_list`, formData)
          .then((res) => {
            if (res.data.status_code === 200) {
              toast.success("User added successfully");

              let old_data = editList;
              let new_data = res.data.response.data;
              setNewData(new_data);
              combine_data = [...new_data, ...old_data];
              setEditList(old_data);
              setShowSaveReader(true);
              setIsOpenAdd(false);
              setActiveManual("active");
              setActiveExcel("");
              setSelectedFile(null);
              //    setUpdatedData(combine_data);

              loader("hide");
            } else {
              toast.warning(res.data.message);
              loader("hide");
            }
          })
          .catch((err) => {
            loader("hide");
            //console.log("something went wrong");
          });
        setIsOpen(false);
      } else {
        toast.warning("Please add a excel file");
      }
    }
  };

  const uploadFile = async (event) => {
    if (validator2.allValid()) {
      setShowUploadMenu(!showUploadMenu);

      let formData = new FormData();
      let user_id = localStorage.getItem("user_id");
      formData.append("user_id", user_id);
      formData.append("smart_list_id", getlistid);
      formData.append("reader_file", selectedFile);

      axios.defaults.baseURL = process.env.REACT_APP_API_KEY;
      loader("show");
      await axios
        .post(`distributes/update_reader_list`, formData)
        .then((res) => {
          let old_data = editList;
          let new_data = res.data.response.data[0];

          combine_data = [new_data, ...old_data];

          setEditList(combine_data);
          setUpdatedData(combine_data);
          loader("hide");
        })
        .catch((err) => {
          //console.log(err);
        });
    } else {
      validator2.showMessages();
      setFileValidationMeassage(fileValidationMessage + 1);
    }
  };

  const showSucessPopup = () => {
    popup_alert({
      visible: "show",
      message: "The HCP record has been deleted <br/>successfully !",
      type: "success",
    });

    setOpenDeleteConfirmation(false);
  };

  const deleteNewlyAdded = (profile_user_id) => {
    const data = newData;
    const dataUpdated = data.filter((d) => {
      return d.profile_user_id != profile_user_id;
    });
    setNewData(dataUpdated);
  };

  const handleSort = (key) => {
    setSortBy(key);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortData = (data, key, order) => {
    return data.sort((a, b) => {
      const valueA = a[key];
      const valueB = b[key];

      // Handle different data types (numbers, strings)
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      } else {
        return order === 'asc'
          ? valueA?.localeCompare(valueB) // Handle string sorting with locale awareness
          : valueB?.localeCompare(valueA);
      }
    });
  };

  return (
    <>
      <div className="page-top-nav smart_list_names sticky">
        {/* {console.log("props.data",props.data)} */}
        <div className="row justify-content-end align-items-center">
          <div className="col-12 col-md-1">
            <div className="header-btn-left">
              {props.url ? (
                <Link
                  to={{
                    pathname: "/CreateSmartList",
                  }}
                  onClick={backClicked}
                >
                  <button className="btn btn-primary btn-bordered back">
                    Back
                  </button>
                </Link>
              ) : (
                <button
                  className="btn btn-primary btn-bordered back"
                  onClick={backClicked}
                >
                  Back
                </button>
              )}
            </div>
          </div>
          <div className="col-12 col-md-11">
            <div className="smart-list-btns">
              {editable == false ? (
                <>
                  <div className="smart-list-download">
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="btn btn-outline-primary"
                      table="table-to-xls"
                      filename="tablexls"
                      sheet="tablexls"
                      buttonText="Download"
                    />
                  </div>
                  <div className="hcp-new-user">
                    <button
                      className="btn btn-outline-primary"
                      onClick={handleShow}
                    >
                      <img src={path + "new-user.svg"} alt="New User" />
                    </button>
                  </div>
                  <div className="hcp-added">
                    <button
                      className="btn btn-outline-primary"
                      onClick={editButtonClicked}
                    >
                      <img src={path + "edit-button.svg"} alt="Edit" />
                    </button>
                  </div>
                </>
              ) : null}

              <div className="top-right-action">
                <div className="search-bar">
                  <form className="d-flex" onSubmit={(e) => submitHandler(e)}>
                    <input
                      className="form-control me-2"
                      type="text"
                      placeholder="Search"
                      aria-label="Search"
                      onChange={(e) => searchChange(e)}
                    />
                    <button className="btn btn-outline-success" type="submit">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.8045 14.862L11.2545 10.312C12.1359 9.22334 12.6665 7.84 12.6665 6.33334C12.6665 2.84134 9.82522 0 6.33325 0C2.84128 0 0 2.84131 0 6.33331C0 9.82531 2.84132 12.6667 6.33328 12.6667C7.83992 12.6667 9.22325 12.136 10.3119 11.2547L14.8619 15.8047C14.9919 15.9347 15.1625 16 15.3332 16C15.5039 16 15.6745 15.9347 15.8045 15.8047C16.0652 15.544 16.0652 15.1227 15.8045 14.862ZM6.33328 11.3333C3.57597 11.3333 1.33333 9.09066 1.33333 6.33331C1.33333 3.57597 3.57597 1.33331 6.33328 1.33331C9.0906 1.33331 11.3332 3.57597 11.3332 6.33331C11.3332 9.09066 9.09057 11.3333 6.33328 11.3333Z"
                          fill="#97B6CF"
                        ></path>
                      </svg>
                    </button>
                  </form>
                </div>
                {/* <div className="filter-by">
                  <button className="btn btn-outline-primary" type="submit">
                    Filter By{" "}
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.615385 2.46154H3.07692C3.07692 3.14031 3.62892 3.69231 4.30769 3.69231H5.53846C6.21723 3.69231 6.76923 3.14031 6.76923 2.46154H15.3846C15.7243 2.46154 16 2.18646 16 1.84615C16 1.50585 15.7243 1.23077 15.3846 1.23077H6.76923C6.76923 0.552 6.21723 0 5.53846 0H4.30769C3.62892 0 3.07692 0.552 3.07692 1.23077H0.615385C0.275692 1.23077 0 1.50585 0 1.84615C0 2.18646 0.275692 2.46154 0.615385 2.46154Z"
                        fill="#97B6CF"
                      ></path>
                      <path
                        d="M15.3846 6.15362H11.6923C11.6923 5.47485 11.1403 4.92285 10.4615 4.92285H9.23077C8.552 4.92285 8 5.47485 8 6.15362H0.615385C0.275692 6.15362 0 6.4287 0 6.76901C0 7.10931 0.275692 7.38439 0.615385 7.38439H8C8 8.06316 8.552 8.61516 9.23077 8.61516H10.4615C11.1403 8.61516 11.6923 8.06316 11.6923 7.38439H15.3846C15.7243 7.38439 16 7.10931 16 6.76901C16 6.4287 15.7243 6.15362 15.3846 6.15362Z"
                        fill="#97B6CF"
                      ></path>
                      <path
                        d="M15.3846 11.077H6.76923C6.76923 10.3982 6.21723 9.84619 5.53846 9.84619H4.30769C3.62892 9.84619 3.07692 10.3982 3.07692 11.077H0.615385C0.275692 11.077 0 11.352 0 11.6923C0 12.0327 0.275692 12.3077 0.615385 12.3077H3.07692C3.07692 12.9865 3.62892 13.5385 4.30769 13.5385H5.53846C6.21723 13.5385 6.76923 12.9865 6.76923 12.3077H15.3846C15.7243 12.3077 16 12.0327 16 11.6923C16 11.352 15.7243 11.077 15.3846 11.077Z"
                        fill="#97B6CF"
                      ></path>
                    </svg>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="search-hcp smart-list-view">
        <div className="result-hcp-table">
          <div className="table-title">
            <h4>
              {getlistname} <span>| {props.list_count}</span>
            </h4>
            <div className="selected-hcp-table-action">
              {editable == false ? (
                <>
                  <a
                    className="show-less-info"
                    onClick={(e) => showMoreInfo(e)}
                  >
                    {showLessInfo == true ? (
                      <p className="show_more">Show More information</p>
                    ) : (
                      <p className="show_less">Show less information</p>
                    )}{" "}
                  </a>
                </>
              ) : null}
              {saveOpen ? (
                <>
                  <button
                    className="btn btn-primary btn-filled"
                    onClick={closeClicked}
                  >
                    Close
                  </button>

                  <button
                    className="btn btn-primary btn-bordered"
                    onClick={saveEditClicked}
                  >
                    Save
                  </button>
                </>
              ) : null}
              {showReaders && !saveOpen ? (
                <div className="row">
                  <div className="col-md-12">
                    <button
                      className="btn btn-primary btn-filled next"
                      onClick={showFileInReadersList}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="selected-hcp-list">
            <table className="table" id="table-to-xls">
              <thead className="sticky-header">
                <tr>
                  <th scope="col" className="sort_option">
                    <span onClick={() => handleSort('first_name')} >
                      Name
                      <button
                        className={`event_sort_btn ${sortBy == "first_name" ?
                          sortOrder == "asc"
                            ? "svg_asc"
                            : "svg_active"
                          : ""
                          }`}
                        onClick={() => handleSort('first_name')}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                          <g clip-path="url(#clip0_3722_6611)">
                            <path d="M7.00015 5.19137L4.3311 7.84461C4.28138 7.89413 4.22222 7.93328 4.15708 7.95976C4.02649 8.01341 3.87983 8.01341 3.74925 7.95976C3.6841 7.93328 3.62494 7.89413 3.57522 7.84461L0.90617 5.19137C0.806076 5.09173 0.7499 4.95664 0.75 4.81582C0.7501 4.67501 0.806468 4.54 0.906704 4.4405C1.00694 4.341 1.14283 4.28516 1.28449 4.28526C1.42614 4.28536 1.56195 4.34139 1.66205 4.44103L3.41988 6.18845L3.41357 0.530648C3.41357 0.389912 3.46981 0.254939 3.56992 0.155423C3.67003 0.0559068 3.8058 4.76837e-07 3.94738 4.76837e-07C4.08895 4.76837e-07 4.22473 0.0559068 4.32484 0.155423C4.42495 0.254939 4.48119 0.389912 4.48119 0.530648L4.48751 6.18845L6.24534 4.44103C6.34602 4.34437 6.48086 4.29088 6.62083 4.29209C6.76079 4.2933 6.89468 4.34911 6.99365 4.44749C7.09262 4.54588 7.14876 4.67897 7.14998 4.81811C7.1512 4.95724 7.09739 5.09129 7.00015 5.19137Z" fill="#97B6CF" />
                          </g>
                          <defs>
                            <clipPath id="clip0_3722_6611">
                              <rect width="8" height="8" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </span>
                  </th>
                  <th scope="col" className="sort_option">
                    <span onClick={() => handleSort('email')} >
                      Email
                      <button
                        className={`event_sort_btn ${sortBy == "email" ?
                          sortOrder == "asc"
                            ? "svg_asc"
                            : "svg_active"
                          : ""
                          }`}
                        onClick={() => handleSort('email')}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                          <g clip-path="url(#clip0_3722_6611)">
                            <path d="M7.00015 5.19137L4.3311 7.84461C4.28138 7.89413 4.22222 7.93328 4.15708 7.95976C4.02649 8.01341 3.87983 8.01341 3.74925 7.95976C3.6841 7.93328 3.62494 7.89413 3.57522 7.84461L0.90617 5.19137C0.806076 5.09173 0.7499 4.95664 0.75 4.81582C0.7501 4.67501 0.806468 4.54 0.906704 4.4405C1.00694 4.341 1.14283 4.28516 1.28449 4.28526C1.42614 4.28536 1.56195 4.34139 1.66205 4.44103L3.41988 6.18845L3.41357 0.530648C3.41357 0.389912 3.46981 0.254939 3.56992 0.155423C3.67003 0.0559068 3.8058 4.76837e-07 3.94738 4.76837e-07C4.08895 4.76837e-07 4.22473 0.0559068 4.32484 0.155423C4.42495 0.254939 4.48119 0.389912 4.48119 0.530648L4.48751 6.18845L6.24534 4.44103C6.34602 4.34437 6.48086 4.29088 6.62083 4.29209C6.76079 4.2933 6.89468 4.34911 6.99365 4.44749C7.09262 4.54588 7.14876 4.67897 7.14998 4.81811C7.1512 4.95724 7.09739 5.09129 7.00015 5.19137Z" fill="#97B6CF" />
                          </g>
                          <defs>
                            <clipPath id="clip0_3722_6611">
                              <rect width="8" height="8" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </span>
                  </th>
                  <th scope="col" className="sort_option">
                    Bounced
                  </th>
                  <th scope="col" className="sort_option">
                    <span onClick={() => handleSort('country')} >
                      Country
                      <button
                        className={`event_sort_btn ${sortBy == "country" ?
                          sortOrder == "asc"
                            ? "svg_asc"
                            : "svg_active"
                          : ""
                          }`}
                        onClick={() => handleSort('country')}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                          <g clip-path="url(#clip0_3722_6611)">
                            <path d="M7.00015 5.19137L4.3311 7.84461C4.28138 7.89413 4.22222 7.93328 4.15708 7.95976C4.02649 8.01341 3.87983 8.01341 3.74925 7.95976C3.6841 7.93328 3.62494 7.89413 3.57522 7.84461L0.90617 5.19137C0.806076 5.09173 0.7499 4.95664 0.75 4.81582C0.7501 4.67501 0.806468 4.54 0.906704 4.4405C1.00694 4.341 1.14283 4.28516 1.28449 4.28526C1.42614 4.28536 1.56195 4.34139 1.66205 4.44103L3.41988 6.18845L3.41357 0.530648C3.41357 0.389912 3.46981 0.254939 3.56992 0.155423C3.67003 0.0559068 3.8058 4.76837e-07 3.94738 4.76837e-07C4.08895 4.76837e-07 4.22473 0.0559068 4.32484 0.155423C4.42495 0.254939 4.48119 0.389912 4.48119 0.530648L4.48751 6.18845L6.24534 4.44103C6.34602 4.34437 6.48086 4.29088 6.62083 4.29209C6.76079 4.2933 6.89468 4.34911 6.99365 4.44749C7.09262 4.54588 7.14876 4.67897 7.14998 4.81811C7.1512 4.95724 7.09739 5.09129 7.00015 5.19137Z" fill="#97B6CF" />
                          </g>
                          <defs>
                            <clipPath id="clip0_3722_6611">
                              <rect width="8" height="8" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </span>
                  </th>

                  {(localStorage.getItem("user_id") ==
                    "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") ? (
                    <>
                      <th scope="col" className="sort_option">
                        <span onClick={() => handleSort('site_number')} >
                          Site number

                          <button
                            className={`event_sort_btn ${sortBy == "site_number" ?
                              sortOrder == "asc"
                                ? "svg_asc"
                                : "svg_active"
                              : ""
                              }`}
                            onClick={() => handleSort('site_number')}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                              <g clip-path="url(#clip0_3722_6611)">
                                <path d="M7.00015 5.19137L4.3311 7.84461C4.28138 7.89413 4.22222 7.93328 4.15708 7.95976C4.02649 8.01341 3.87983 8.01341 3.74925 7.95976C3.6841 7.93328 3.62494 7.89413 3.57522 7.84461L0.90617 5.19137C0.806076 5.09173 0.7499 4.95664 0.75 4.81582C0.7501 4.67501 0.806468 4.54 0.906704 4.4405C1.00694 4.341 1.14283 4.28516 1.28449 4.28526C1.42614 4.28536 1.56195 4.34139 1.66205 4.44103L3.41988 6.18845L3.41357 0.530648C3.41357 0.389912 3.46981 0.254939 3.56992 0.155423C3.67003 0.0559068 3.8058 4.76837e-07 3.94738 4.76837e-07C4.08895 4.76837e-07 4.22473 0.0559068 4.32484 0.155423C4.42495 0.254939 4.48119 0.389912 4.48119 0.530648L4.48751 6.18845L6.24534 4.44103C6.34602 4.34437 6.48086 4.29088 6.62083 4.29209C6.76079 4.2933 6.89468 4.34911 6.99365 4.44749C7.09262 4.54588 7.14876 4.67897 7.14998 4.81811C7.1512 4.95724 7.09739 5.09129 7.00015 5.19137Z" fill="#97B6CF" />
                              </g>
                              <defs>
                                <clipPath id="clip0_3722_6611">
                                  <rect width="8" height="8" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>                      </span>
                      </th>
                      <th scope="col" className="sort_option">
                        <span onClick={() => handleSort('irt')} >
                          IRT mandatory training
                          <button
                            className={`event_sort_btn ${sortBy == "irt" ?
                              sortOrder == "asc"
                                ? "svg_asc"
                                : "svg_active"
                              : ""
                              }`}
                            onClick={() => handleSort('irt')}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                              <g clip-path="url(#clip0_3722_6611)">
                                <path d="M7.00015 5.19137L4.3311 7.84461C4.28138 7.89413 4.22222 7.93328 4.15708 7.95976C4.02649 8.01341 3.87983 8.01341 3.74925 7.95976C3.6841 7.93328 3.62494 7.89413 3.57522 7.84461L0.90617 5.19137C0.806076 5.09173 0.7499 4.95664 0.75 4.81582C0.7501 4.67501 0.806468 4.54 0.906704 4.4405C1.00694 4.341 1.14283 4.28516 1.28449 4.28526C1.42614 4.28536 1.56195 4.34139 1.66205 4.44103L3.41988 6.18845L3.41357 0.530648C3.41357 0.389912 3.46981 0.254939 3.56992 0.155423C3.67003 0.0559068 3.8058 4.76837e-07 3.94738 4.76837e-07C4.08895 4.76837e-07 4.22473 0.0559068 4.32484 0.155423C4.42495 0.254939 4.48119 0.389912 4.48119 0.530648L4.48751 6.18845L6.24534 4.44103C6.34602 4.34437 6.48086 4.29088 6.62083 4.29209C6.76079 4.2933 6.89468 4.34911 6.99365 4.44749C7.09262 4.54588 7.14876 4.67897 7.14998 4.81811C7.1512 4.95724 7.09739 5.09129 7.00015 5.19137Z" fill="#97B6CF" />
                              </g>
                              <defs>
                                <clipPath id="clip0_3722_6611">
                                  <rect width="8" height="8" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                        </span>
                      </th>
                      <th scope="col" className="sort_option">
                        <span onClick={() => handleSort('user_type')} >
                          IRT role
                          <button
                            className={`event_sort_btn ${sortBy == "user_type" ?
                              sortOrder == "asc"
                                ? "svg_asc"
                                : "svg_active"
                              : ""
                              }`}
                            onClick={() => handleSort('user_type')}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                              <g clip-path="url(#clip0_3722_6611)">
                                <path d="M7.00015 5.19137L4.3311 7.84461C4.28138 7.89413 4.22222 7.93328 4.15708 7.95976C4.02649 8.01341 3.87983 8.01341 3.74925 7.95976C3.6841 7.93328 3.62494 7.89413 3.57522 7.84461L0.90617 5.19137C0.806076 5.09173 0.7499 4.95664 0.75 4.81582C0.7501 4.67501 0.806468 4.54 0.906704 4.4405C1.00694 4.341 1.14283 4.28516 1.28449 4.28526C1.42614 4.28536 1.56195 4.34139 1.66205 4.44103L3.41988 6.18845L3.41357 0.530648C3.41357 0.389912 3.46981 0.254939 3.56992 0.155423C3.67003 0.0559068 3.8058 4.76837e-07 3.94738 4.76837e-07C4.08895 4.76837e-07 4.22473 0.0559068 4.32484 0.155423C4.42495 0.254939 4.48119 0.389912 4.48119 0.530648L4.48751 6.18845L6.24534 4.44103C6.34602 4.34437 6.48086 4.29088 6.62083 4.29209C6.76079 4.2933 6.89468 4.34911 6.99365 4.44749C7.09262 4.54588 7.14876 4.67897 7.14998 4.81811C7.1512 4.95724 7.09739 5.09129 7.00015 5.19137Z" fill="#97B6CF" />
                              </g>
                              <defs>
                                <clipPath id="clip0_3722_6611">
                                  <rect width="8" height="8" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                        </span>
                      </th>
                    </>
                  ) : (
                    <>
                      <th scope="col" className="sort_option">
                        <span onClick={() => handleSort('ibu')} >
                          Business unit
                          <button
                            className={`event_sort_btn ${sortBy == "ibu" ?
                              sortOrder == "asc"
                                ? "svg_asc"
                                : "svg_active"
                              : ""
                              }`}
                            onClick={() => handleSort('ibu')}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                              <g clip-path="url(#clip0_3722_6611)">
                                <path d="M7.00015 5.19137L4.3311 7.84461C4.28138 7.89413 4.22222 7.93328 4.15708 7.95976C4.02649 8.01341 3.87983 8.01341 3.74925 7.95976C3.6841 7.93328 3.62494 7.89413 3.57522 7.84461L0.90617 5.19137C0.806076 5.09173 0.7499 4.95664 0.75 4.81582C0.7501 4.67501 0.806468 4.54 0.906704 4.4405C1.00694 4.341 1.14283 4.28516 1.28449 4.28526C1.42614 4.28536 1.56195 4.34139 1.66205 4.44103L3.41988 6.18845L3.41357 0.530648C3.41357 0.389912 3.46981 0.254939 3.56992 0.155423C3.67003 0.0559068 3.8058 4.76837e-07 3.94738 4.76837e-07C4.08895 4.76837e-07 4.22473 0.0559068 4.32484 0.155423C4.42495 0.254939 4.48119 0.389912 4.48119 0.530648L4.48751 6.18845L6.24534 4.44103C6.34602 4.34437 6.48086 4.29088 6.62083 4.29209C6.76079 4.2933 6.89468 4.34911 6.99365 4.44749C7.09262 4.54588 7.14876 4.67897 7.14998 4.81811C7.1512 4.95724 7.09739 5.09129 7.00015 5.19137Z" fill="#97B6CF" />
                              </g>
                              <defs>
                                <clipPath id="clip0_3722_6611">
                                  <rect width="8" height="8" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                        </span>
                      </th>
                      <th scope="col">Contact type</th>
                    </>
                  )}

                  {showLessInfo == false ? (
                    <>
                      {" "}
                      <th scope="col">Consent</th>
                      <th scope="col">Email received</th>
                      <th scope="col">Openings</th>
                      <th scope="col">Registrations</th>
                      <th scope="col">Last email</th>
                      <th scope="col"></th>{" "}
                    </>
                  ) : null}
                </tr>
              </thead>
              <tbody className="form-group">
                {newData.map((item, index) => (
                  <tr
                    key={item}
                    className="hcps-added"
                    onClick={(e) =>
                      editing(
                        item.profile_id,
                        item.profile_user_id,
                        item.email,
                        item.jobTitle,
                        item.company,
                        item.country,
                        item.first_name + " " + item.last_name,
                        item.contact_type
                      )
                    }
                  >
                    <td
                      contenteditable={editable === 0 ? "false" : "true"}
                      id={`field_name` + item.profile_user_id}
                    >
                      {inEditMode.status &&
                        inEditMode.rowKey === item.profile_id ? (
                        <input
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                        />
                      ) : (
                        <span>{item.first_name + " " + item.last_name}</span>
                      )}
                    </td>
                    <td>
                      {" "}
                      {inEditMode.status &&
                        inEditMode.rowKey === item.profile_id ? (
                        <input
                          value={email}
                          type="email"
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      ) : (
                        item.email
                      )}
                    </td>
                    <input
                      type="hidden"
                      id={`field_index` + item.profile_user_id}
                      value={index}
                    />
                    <td>{item.bounce ? item.bounce : "N/A"}</td>
                    <td>
                      {editable ? (
                        <EditCountry
                          selected_country={item.country}
                          profile_user={item.profile_user_id}
                        ></EditCountry>
                      ) : (
                        <span>{item.country ? item.country : "N/A"}</span>
                      )}
                    </td>
                    {(localStorage.getItem("user_id") == "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") && (<>
                      <td>{item?.site_number ? item?.site_number : "N/A"}
                      </td></>)}
                    <td>
                      {(localStorage.getItem("user_id") ==
                        "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
                        ? item?.irt
                          ? "Yes"
                          : "No"
                        : item.ibu
                          ? item.ibu
                          : "N/A"}
                    </td>
                    <td>
                      {(localStorage.getItem("user_id") ==
                        "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") ? (
                        <span>
                          {item.user_type != 0 ? item.user_type : "N/A"}
                        </span>
                      ) : editable ? (
                        <EditContactType
                          selected_ibu={item.contact_type}
                          profile_user={item.profile_user_id}
                        ></EditContactType>
                      ) : (
                        <span>
                          {item.contact_type ? item.contact_type : "N/A"}
                        </span>
                      )}
                    </td>
                    {showLessInfo == false ? (
                      <td>
                        <span>{item.consent ? item.consent : "N/A"}</span>{" "}
                      </td>
                    ) : null}
                    {showLessInfo == false ? (
                      <td>
                        <span>
                          {item.email_received ? item.email_received : "N/A"}
                        </span>
                      </td>
                    ) : null}
                    {showLessInfo == false ? (
                      <td>
                        <span>
                          {item.email_opening ? item.email_opening : "N/A"}
                        </span>
                      </td>
                    ) : null}
                    {showLessInfo == false ? (
                      <td>
                        <span>
                          {item.registration ? item.registration : "N/A"}
                        </span>
                      </td>
                    ) : null}
                    {showLessInfo == false ? (
                      <td>
                        <span>{item.last_email ? item.last_email : "N/A"}</span>
                      </td>
                    ) : null}
                    <td className="delete_row" colspan="12">
                      <img
                        src={path + "delete.svg"}
                        alt="Delete Row"
                        onClick={() => deleteNewlyAdded(item.profile_user_id)}
                      />
                    </td>
                  </tr>
                ))}

                <tr className="seprator-add">
                  <td colspan="13"></td>
                </tr>

                {sortData(editList, sortBy, sortOrder)?.map((item, index) => (
                  <tr
                    key={item}
                    id={`row-selected` + index}
                    onClick={(e) =>
                      editing(
                        //  e.currentTarget,
                        item.profile_id,
                        item.profile_user_id,
                        item.email,
                        item.jobTitle,
                        item.company,
                        item.country,
                        item.first_name + " " + item.last_name,
                        item.contact_type
                      )
                    }
                  >
                    <td
                      id={`field_name` + item.profile_user_id}
                      contenteditable={editable === 0 ? "false" : "true"}
                    >
                      <span> {item.first_name + " " + item.last_name} </span>
                    </td>

                    <td id={`field_email` + item.profile_user_id}>
                      {item.email}
                    </td>
                    <input
                      type="hidden"
                      id={`field_index` + item.profile_user_id}
                      value={index}
                    />
                    <td id={`field_bounced` + item.profile_user_id}>
                      {item.bounce}
                    </td>
                    <td>
                      {editable ? (
                        <EditCountry
                          selected_country={item.country}
                          profile_user={item.profile_user_id}
                        ></EditCountry>
                      ) : (
                        <span>{item.country ? item.country : "N/A"}</span>
                      )}
                    </td>
                    {(localStorage.getItem("user_id") ==
                      "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") && (<><td id="field_site_number">{item?.site_number ? item?.site_number : "N/A"}</td></>)}
                    <td id="field_business_unit">
                      {/*item.ibu*/}
                      {(localStorage.getItem("user_id") ==
                        "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
                        ? item?.irt
                          ? "Yes"
                          : "No"
                        : item.ibu
                          ? item.ibu
                          : "N/A"}
                    </td>

                    <td id="field_interest">
                      {(localStorage.getItem("user_id") ==
                        "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") ? (
                        <span>
                          {item.user_type != 0 ? item.user_type : "N/A"}
                        </span>
                      ) : editable ? (
                        <EditContactType
                          selected_ibu={item.contact_type}
                          profile_user={item.profile_user_id}
                        ></EditContactType>
                      ) : (
                        <span>
                          {item.contact_type ? item.contact_type : "N/A"}
                        </span>
                      )}
                    </td>

                    {showLessInfo == false ? (
                      <td>
                        <span>{item.consent ? item.consent : "N/A"}</span>{" "}
                      </td>
                    ) : null}
                    {showLessInfo == false ? (
                      <td>
                        <span>
                          {item.email_received ? item.email_received : "N/A"}
                        </span>
                      </td>
                    ) : null}
                    {showLessInfo == false ? (
                      <td>
                        <span>
                          {item.email_opening ? item.email_opening : "N/A"}
                        </span>
                      </td>
                    ) : null}
                    {showLessInfo == false ? (
                      <td>
                        <span>
                          {item.registration ? item.registration : "N/A"}
                        </span>
                      </td>
                    ) : null}
                    {showLessInfo == false ? (
                      <td>
                        <span>{item.last_email ? item.last_email : "N/A"}</span>
                      </td>
                    ) : null}

                    <td className="delete_row" colspan="12">
                      <img
                        src={path + "delete.svg"}
                        alt="Delete Row"
                        onClick={() =>
                          onDelete({
                            id: item.profile_id,
                            currentName: item.first_name + " " + item.last_name,
                            currentJobTitle: item.jobTitle,
                            currentCompany: item.company,
                            currentIndication: item.indication,
                            currentProduct: item.product,
                            currentCountry: item.country,
                            currentEmail: item.email,
                            profile_user_id: item.profile_user_id,
                          })
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <input type="hidden" value={updateCounter} />
      </section>

      <Modal show={isOpen} className="send-confirm" id="resend-confirm">
        <Modal.Header>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => {
              setIsOpen(false);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <img src={path + "alert.png"} alt="" />
          <h4>
            The HCP record will be deleted from the list.
            <br /> Are you sure you want to delete it?{" "}
          </h4>

          <div className="modal-buttons">
            <button
              type="button"
              className="btn btn-primary btn-filled"
              data-bs-dismiss="modal"
              onClick={() => {
                deleteReader(profile_user_id);
                setIsOpen(false);

                setOpenDeleteConfirmation(true);
              }}
            >
              Yes please!
            </button>

            <button
              type="button"
              className="btn btn-primary btn-bordered light"
              data-bs-dismiss="modal"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {deleteConfirmation == true ? showSucessPopup() : null}

      <Modal
        id="add_hcp"
        show={isOpenAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-hidden="true"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {localStorage.getItem("user_id") == userId || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA=="
                ? "Add New User +"
                : "Add New HCP"}
            </h5>
            <button
              onClick={() => {
                setIsOpenAdd(false);
                setHpc([
                  {
                    firstname: "",
                    lastname: "",
                    email: "",
                    contact_type: "",
                    country: "",
                    userType:
                      (localStorage.getItem("user_id") ==
                        "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
                        ? irtRole?.[0]?.value
                        : "",
                    roleIndex:
                      (localStorage.getItem("user_id") ==
                        "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
                        ? 0
                        : "",
                    countryIndex: "",
                    siteIrt:
                      (localStorage.getItem("user_id") ==
                        "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
                        ? siteIrtAll?.find((item) => item?.value == "Yes")
                          ?.value
                        : "",
                    siteIrtIndex:
                      (localStorage.getItem("user_id") ==
                        "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
                        ? siteIrtAll?.indexOf((item) => item?.value == "Yes")
                        : "",
                    institute: localStorage.getItem("user_id") == userId
                      ? irtInstitutionType?.[0]?.value
                      : "",
                    instituteIndex: localStorage.getItem("user_id") == userId ? 0 : "",
                    siteNumber: "",
                    siteName: ""
                  },
                ]);
                setActiveManual("active");
                // document.querySelector("#file-4").value = "";
                setActiveExcel("");
              }}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="hcp-add-box">
              <div className="hcp-add-form tab-content" id="upload-confirm">
                <form id="add_hcp_form" className={"tab-pane" + activeManual}>
                  {hpc.map((val, i) => {
                    const fieldName = `hpc[${i}]`;
                    return (
                      <>
                        <div className="add_hcp_boxes">
                          <div className="form_action">
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="form-group">
                                  <label htmlFor="">
                                    First name{" "}
                                    {(localStorage.getItem("user_id") ==
                                      "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") && (
                                        <span>*</span>
                                      )}{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className={(validationError?.firstName &&
                                      validationError?.index == i)
                                      ? "form-control error"
                                      : "form-control"}
                                    onChange={(event) =>
                                      onFirstNameChange(event, i)
                                    }
                                    value={val.firstname}
                                    placeholder="First name"
                                  />
                                  {(validationError?.firstName &&
                                    validationError?.index == i) ? (
                                    <div className="login-validation">
                                      {validationError?.firstName}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="form-group">
                                  <label htmlFor="">
                                    Last name{" "}
                                    {(localStorage.getItem("user_id") ==
                                      "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") && (
                                        <span>*</span>
                                      )}
                                  </label>
                                  <input
                                    type="text"
                                    className={(validationError?.lastName &&
                                      validationError?.index == i)
                                      ? "form-control error"
                                      : "form-control"}
                                    onChange={(event) =>
                                      onLastNameChange(event, i)
                                    }
                                    value={val.lastname}
                                    placeholder="Last name"
                                  />
                                  {(validationError?.lastName &&
                                    validationError?.index == i) ? (
                                    <div className="login-validation">
                                      {validationError?.lastName}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="form-group">
                                  <label htmlFor="">
                                    Email <span>*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className={
                                      (validationError?.email &&
                                        validationError?.index == i)
                                        ? "form-control error"
                                        : "form-control"
                                    }
                                    id="email-desc"
                                    name={`${fieldName}.email`}
                                    onChange={(event) =>
                                      onEmailChange(event, i)
                                    }
                                    value={val.email}
                                    placeholder="example@email.com"
                                  />
                                  {(validationError?.email &&
                                    validationError?.index == i) ? (
                                    <div className="login-validation">
                                      {validationError?.email}
                                    </div>
                                  ) : null}
                                </div>
                              </div>

                              {(localStorage.getItem("user_id") !=
                                "56Ek4feL/1A8mZgIKQWEqg==" && localStorage.getItem("user_id") != "sNl1hra39QmFk9HwvXETJA==") ? (
                                <div className="col-12 col-md-6">
                                  <div className="form-group">
                                    <label for="">Contact type</label>
                                    <DropdownButton
                                      className="dropdown-basic-button split-button-dropup"
                                      title={
                                        hpc[i].contact_type != "" &&
                                          hpc[i].contact_type != "undefined"
                                          ? hpc[i].contact_type
                                          : "Select Type"
                                      }
                                      onSelect={(event) =>
                                        onContactTypeChange(event, i)
                                      }
                                    >
                                      <div className="scroll_div">
                                        <Dropdown.Item
                                          eventKey="HCP"
                                          className={
                                            hpc[i].contact_type == "HCP"
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          HCP
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          eventKey="Staff"
                                          className={
                                            hpc[i].contact_type == "Staff"
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          Staff
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          eventKey="Test Users"
                                          className={
                                            hpc[i].contact_type == "Test Users"
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          Test Users
                                        </Dropdown.Item>
                                      </div>
                                    </DropdownButton>
                                  </div>
                                </div>
                              ) : null}

                              {(localStorage.getItem("user_id") !=
                                "56Ek4feL/1A8mZgIKQWEqg==" && localStorage.getItem("user_id") != "sNl1hra39QmFk9HwvXETJA==") ? (
                                <div className="col-12 col-md-6">
                                  <div className="form-group">
                                    <label for="">Country
                                      {localStorage.getItem("user_id") ==
                                        "m5JI5zEDY3xHFTZBnSGQZg==" && (
                                          <span> *</span>
                                        )}
                                    </label>

                                    <Select
                                      options={countryall}
                                      className={(validationError?.country &&
                                        validationError?.index == i)
                                        ? "dropdown-basic-button split-button-dropup edit-country-dropdown error"
                                        : "dropdown-basic-button split-button-dropup edit-country-dropdown"}
                                      onChange={(event) =>
                                        onCountryChange(event, i)
                                      }
                                      defaultValue={
                                        countryall[hpc[i].countryIndex]
                                      }
                                      placeholder={
                                        typeof countryall[
                                          hpc[i].countryIndex
                                        ] === "undefined"
                                          ? "Select Country"
                                          : countryall[hpc[i].countryIndex]
                                      }
                                      filterOption={createFilter(filterConfig)}
                                      isClearable
                                    />
                                    {(validationError?.country &&
                                      validationError?.index == i) ? (
                                      <div className="login-validation">
                                        {validationError?.country}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}

                              {(localStorage.getItem("user_id") ==
                                "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") ? (
                                <>
                                  <hr />
                                  <div className="col-12 col-md-6">
                                    <div className="form-group">
                                      <label for="">
                                        IRT mandatory training
                                      </label>
                                      <Select
                                        options={siteIrtAll}
                                        className="dropdown-basic-button split-button-dropup edit-country-dropdown"
                                        onChange={(event) =>
                                          onSiteIrtChange(event, i)
                                        }
                                        value={siteIrtAll[hpc[i].siteIrtIndex]}
                                        placeholder={
                                          typeof siteIrtAll[
                                            hpc[i].siteIrtIndex
                                          ] === "undefined"
                                            ? "Select Site IRT"
                                            : siteIrtAll[hpc[i].siteIrtIndex]
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group">
                                      <label for="">
                                        Institution <span>*</span>
                                      </label>
                                      {siteIrtAll[hpc[i].siteIrtIndex]
                                        ?.value === "Yes" ? (<>

                                          <Select
                                            options={irtInstitutionType}
                                            className={(validationError?.institutionType &&
                                              validationError?.index == i)
                                              ? "dropdown-basic-button split-button-dropup edit-country-dropdown error"
                                              : "dropdown-basic-button split-button-dropup edit-country-dropdown"}
                                            onChange={(event) =>
                                              onInstitutionChange(event, i)
                                            }
                                            defaultValue={
                                              irtInstitutionType[hpc[i].instituteIndex]
                                            }
                                            placeholder={
                                              typeof irtInstitutionType[
                                                hpc[i].instituteIndex
                                              ] == "undefined"
                                                ? "Select Institutions"
                                                : irtInstitutionType[hpc[i].instituteIndex]
                                            }

                                          /></>)
                                        : (<>
                                          <Select
                                            options={nonIrtInstitutionType}
                                            className={(validationError?.institutionType &&
                                              validationError?.index == i)
                                              ? "dropdown-basic-button split-button-dropup edit-country-dropdown error"
                                              : "dropdown-basic-button split-button-dropup edit-country-dropdown"}
                                            onChange={(event) =>
                                              onInstitutionChange(event, i)
                                            }
                                            defaultValue={
                                              nonIrtInstitutionType[hpc[i].instituteIndex]
                                            }

                                            // value={nonIrtInstitutionType?.findIndex((item)=>item?.value==hpc[i].institute)==-1?"": nonIrtInstitutionType[hpc[i].instituteIndex]}
                                            value={nonIrtInstitutionType[hpc[i].instituteIndex]
                                              ? nonIrtInstitutionType[hpc[i].instituteIndex]
                                              : ""
                                            }
                                            isClearable
                                            placeholder={
                                              typeof nonIrtInstitutionType[
                                                hpc[i].instituteIndex] == "undefined"
                                                ? "Select Institutions"
                                                : nonIrtInstitutionType[
                                                hpc[i].instituteIndex
                                                ]
                                            }
                                          />
                                        </>)}
                                      {(validationError?.institutionType &&
                                        validationError?.index == i) ? (
                                        <div className="login-validation">
                                          {validationError?.institutionType}
                                        </div>
                                      ) : null}

                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6">
                                    <div className="form-group">
                                      <label for="">IRT role <span> *</span></label>
                                      {siteIrtAll[hpc[i].siteIrtIndex]
                                        ?.value === "Yes" ? (
                                        <Select
                                          options={irtRole}
                                          className={(validationError?.role &&
                                            validationError?.index == i)
                                            ? "dropdown-basic-button split-button-dropup edit-country-dropdown error"
                                            : "dropdown-basic-button split-button-dropup edit-country-dropdown"}
                                          onChange={(event) =>
                                            onUserTypeChange(event, i)
                                          }
                                          value={irtRole[hpc[i].roleIndex]}
                                          placeholder={"Select Role"}
                                          isClearable
                                        // filterOption={createFilter(filterConfig)}
                                        />
                                      ) : siteIrtAll[hpc[i].siteIrtIndex]
                                        ?.value === "No" ? (
                                        <Select
                                          options={userTypeAll}
                                          className={(validationError?.role &&
                                            validationError?.index == i)
                                            ? "dropdown-basic-button split-button-dropup edit-country-dropdown error"
                                            : "dropdown-basic-button split-button-dropup edit-country-dropdown"}
                                          onChange={(event) =>
                                            onUserTypeChange(event, i)
                                          }
                                          value={userTypeAll[hpc[i].roleIndex]}
                                          isClearable
                                          placeholder={"Select Role"}
                                        // filterOption={createFilter(filterConfig)}
                                        />
                                      ) : (
                                        <Select
                                          className="dropdown-basic-button split-button-dropup edit-country-dropdown"
                                          placeholder={"Select Role"}
                                        />
                                      )}
                                      {(validationError?.role &&
                                        validationError?.index == i) ? (
                                        <div className="login-validation">
                                          {validationError?.role}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>



                                  <div className="col-12 col-md-6">
                                    <div className="form-group">
                                      <label for="">Study role</label>
                                      <Select
                                        options={subUserTypeAll}
                                        className="dropdown-basic-button split-button-dropup edit-country-dropdown"
                                        onChange={(event) =>
                                          onSubUserTypeChange(event, i)
                                        }
                                        defaultValue={
                                          subUserTypeAll[
                                          hpc[i].subUserTypeIndex
                                          ]
                                        }
                                        placeholder={
                                          typeof subUserTypeAll[
                                            hpc[i].subUserTypeIndex
                                          ] === "undefined"
                                            ? "Select Study Role"
                                            : subUserTypeAll[
                                            hpc[i].subUserTypeIndex
                                            ]
                                        }
                                      // filterOption={createFilter(filterConfig)}
                                      //  isClearable
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6">
                                    <div className="form-group">
                                      <label for="">
                                        Country{" "}
                                        {(localStorage.getItem("user_id") ==
                                          "56Ek4feL/1A8mZgIKQWEqg==" || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==") && (
                                            <span> *</span>
                                          )}
                                      </label>
                                      {siteIrtAll[hpc[i].siteIrtIndex]
                                        ?.value === "Yes" ? (
                                        <Select
                                          options={irtCountry}
                                          className={(validationError?.country &&
                                            validationError?.index == i)
                                            ? "dropdown-basic-button split-button-dropup edit-country-dropdown error"
                                            : "dropdown-basic-button split-button-dropup edit-country-dropdown"}
                                          onChange={(event) =>
                                            onCountryChange(event, i)
                                          }
                                          value={
                                            irtCountry.findIndex(
                                              (el) => el.value == val?.country
                                            ) == -1
                                              ? ""
                                              : irtCountry[
                                              irtCountry.findIndex(
                                                (el) =>
                                                  el.value == val?.country
                                              )
                                              ]
                                          }
                                          placeholder="Select Country"
                                          filterOption={createFilter(
                                            filterConfig
                                          )}
                                          isClearable
                                        />
                                      ) : (
                                        <Select
                                          options={countryall}
                                          className={(validationError?.country &&
                                            validationError?.index == i)
                                            ? "dropdown-basic-button split-button-dropup edit-country-dropdown error"
                                            : "dropdown-basic-button split-button-dropup edit-country-dropdown"}
                                          onChange={(event) =>
                                            onCountryChange(event, i)
                                          }
                                          value={
                                            countryall.findIndex(
                                              (el) => el.value == val?.country
                                            ) == -1
                                              ? ""
                                              : countryall[
                                              countryall.findIndex(
                                                (el) =>
                                                  el.value == val?.country
                                              )
                                              ]
                                          }
                                          placeholder="Select Country"
                                          filterOption={createFilter(
                                            filterConfig
                                          )}
                                          isClearable
                                        />
                                      )}
                                      {(validationError?.country &&
                                        validationError?.index == i) ? (
                                        <div className="login-validation">
                                          {validationError?.country}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6">
                                    <div className="form-group">
                                      <label for="">Site number {siteIrtAll[hpc[i].siteIrtIndex]
                                        ?.value === "Yes" ? <span> *</span> : ""}</label>
                                      <Select
                                        options={siteNumberAll}
                                        className={(validationError?.siteNumber &&
                                          validationError?.index == i)
                                          ? "dropdown-basic-button split-button-dropup edit-country-dropdown error"
                                          : "dropdown-basic-button split-button-dropup edit-country-dropdown"}
                                        onChange={(event) =>
                                          onSiteNumberChange(event, i)
                                        }
                                        value={
                                          siteNumberAll[hpc[i].siteNumberIndex]
                                            ? siteNumberAll[
                                            hpc[i].siteNumberIndex
                                            ]
                                            : ""
                                        }

                                        placeholder={
                                          typeof siteNumberAll[
                                            hpc[i].siteNumberIndex
                                          ] === "undefined"
                                            ? "Select Site Number"
                                            : siteNumberAll[
                                            hpc[i].siteNumberIndex
                                            ]
                                        }

                                      />
                                      {(validationError?.siteNumber &&
                                        validationError?.index == i) ? (
                                        <div className="login-validation">
                                          {validationError?.siteNumber}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group">
                                      <label for="">Site name{siteIrtAll[hpc[i].siteIrtIndex]
                                        ?.value === "Yes" ? <span> *</span> : ""}</label>

                                      <Select
                                        options={siteNameAll}
                                        className={(validationError?.siteName &&
                                          validationError?.index == i)
                                          ? "dropdown-basic-button split-button-dropup edit-country-dropdown error"
                                          : "dropdown-basic-button split-button-dropup edit-country-dropdown"}
                                        onChange={(event) =>
                                          onSiteNameChange(event, i)
                                        }

                                        value={
                                          siteNameAll[hpc[i].siteNameIndex]
                                            ? siteNameAll[hpc[i].siteNameIndex]
                                            : ""
                                        }
                                        defaultValue={
                                          siteNameAll[hpc[i].siteNameIndex]
                                        }
                                        placeholder={
                                          typeof siteNameAll[
                                            hpc[i].siteNameIndex
                                          ] === "undefined"
                                            ? "Select Site Name"
                                            : siteNameAll[hpc[i].siteNameIndex]
                                        }
                                      // filterOption={createFilter(filterConfig)}
                                      />
                                      {(validationError?.siteName &&
                                        validationError?.index == i) ? (
                                        <div className="login-validation">
                                          {validationError?.siteName}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>

                                  {/* <div className="col-12 col-md-6">
                                    <div className="form-group">
                                      <label for="">Site Street</label>
                                      <Select
                                        options={siteStreetAll}
                                        className="dropdown-basic-button split-button-dropup edit-country-dropdown"
                                        onChange={(event) =>
                                          onSiteStreetChange(
                                            event,

                                            i
                                          )
                                        }
                                        defaultValue={
                                          siteStreetAll[hpc[i].siteStreetIndex]
                                        }
                                        placeholder={
                                          typeof siteStreetAll[
                                            hpc[i].siteStreetIndex
                                          ] === "undefined"
                                            ? "Select Site Street"
                                            : siteStreetAll[
                                                hpc[i].siteStreetIndex
                                              ]
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group">
                                      <label for="">Site Post Code</label>
                                      <Select
                                        options={sitePostalCodeAll}
                                        className="dropdown-basic-button split-button-dropup edit-country-dropdown"
                                        onChange={(event) =>
                                          onSitePostCode(
                                            event,

                                            i
                                          )
                                        }
                                        defaultValue={
                                          sitePostalCodeAll[
                                            hpc[i].sitePostCodeIndex
                                          ]
                                        }
                                        placeholder={
                                          typeof sitePostalCodeAll[
                                            hpc[i].sitePostCodeIndex
                                          ] === "undefined"
                                            ? "Select Post Code"
                                            : sitePostalCodeAll[
                                                hpc[i].sitePostCodeIndex
                                              ]
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group">
                                      <label for="">Site City</label>
                                      <Select
                                        options={siteCityAll}
                                        className="dropdown-basic-button split-button-dropup edit-country-dropdown"
                                        onChange={(event) =>
                                          onSiteCityChange(
                                            event,

                                            i
                                          )
                                        }
                                        defaultValue={
                                          siteStreetAll[hpc[i].siteStreetIndex]
                                        }
                                        placeholder={
                                          typeof siteCityAll[
                                            hpc[i].siteCityIndex
                                          ] === "undefined"
                                            ? "Select Site City"
                                            : siteCityAll[hpc[i].siteCityIndex]
                                        }
                                      />
                                    </div>
                                  </div>*/}

                                  {/* <button onClick={(e) => addMoreSite(i, e)}>
                                    +
                                  </button> */}

                                  {val?.siteDetails?.map((data, index) => {
                                    return (
                                      <>
                                        {/* {index !== 0 ? (
                                          <>
                                            <div className="add-content-form">
                                              <div className="row">
                                                <div className="col-12 col-md-6">
                                                  <div className="form-group">
                                                    <label for="">
                                                      Site number
                                                    </label>
                                                    <input
                                                      type="email"
                                                      className="form-control"
                                                      id="email-desc"
                                                      // name={`${fieldName}.email`}
                                                      onChange={(event) =>
                                                        onSiteNumberChange(
                                                          event,
                                                          index,
                                                          i
                                                        )
                                                      }
                                                      value={data.siteNumber}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                  <div className="form-group">
                                                    <label for="">
                                                      Site name
                                                    </label>
                                                    <input
                                                      type="email"
                                                      className="form-control"
                                                      id="email-desc"
                                                      // name={`${fieldName}.email`}
                                                      onChange={(event) =>
                                                        onSiteNameChange(
                                                          event,
                                                          index,
                                                          i
                                                        )
                                                      }
                                                      value={data.siteName}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-12 col-md-6">
                                                  <div className="form-group">
                                                    <label for="">
                                                      Site Street
                                                    </label>
                                                    <input
                                                      type="email"
                                                      className="form-control"
                                                      id="email-desc"
                                                      // name={`${fieldName}.email`}
                                                      onChange={(event) =>
                                                        onSiteStreetChange(
                                                          event,
                                                          index,
                                                          i
                                                        )
                                                      }
                                                      value={data.siteStreet}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                  <div className="form-group">
                                                    <label for="">
                                                      Site Post Code
                                                    </label>
                                                    <input
                                                      type="email"
                                                      className="form-control"
                                                      id="email-desc"
                                                      // name={`${fieldName}.email`}
                                                      onChange={(event) =>
                                                        onSitePostCode(
                                                          event,
                                                          index,
                                                          i
                                                        )
                                                      }
                                                      value={data.sitePostCode}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                  <div className="form-group">
                                                    <label for="">
                                                      Site City
                                                    </label>
                                                    <input
                                                      type="email"
                                                      className="form-control"
                                                      id="email-desc"
                                                      // name={`${fieldName}.email`}
                                                      onChange={(event) =>
                                                        onSiteCityChange(
                                                          event,
                                                          index,
                                                          i
                                                        )
                                                      }
                                                      value={data.siteCity}
                                                    />
                                                  </div>
                                                  <div className="delete_btn">
                                                    {index !== 0 ? (
                                                      <button
                                                        type="button"
                                                        className="btn btn-filled"
                                                        onClick={(e) =>
                                                          removeSite(
                                                            index,
                                                            i,
                                                            e
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={
                                                            path_image +
                                                            "delete.svg"
                                                          }
                                                          alt="Add More"
                                                        />
                                                      </button>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : ( */}
                                        <>
                                          <div className="add-content-form">
                                            <div className="row"></div>
                                          </div>
                                        </>
                                        {/* )} */}
                                      </>
                                    );
                                  })}
                                </>
                              ) : null}
                             
                            </div>
                          </div>

                          <div className="hcp-modal-action">
                            <div className="hcp-action-block">
                              {activeManual == "active" ? (
                                <>
                                  {hpc.length > 1 && (
                                    <div className="hcp-remove">
                                      <button
                                        type="button"
                                        className="btn btn-filled"
                                        onClick={() => deleteRecord(i)}
                                      >
                                        <img
                                          src={path_image + "delete.svg"}
                                          alt="Add More"
                                        />
                                      </button>
                                    </div>
                                  )}
                                </>
                              ) : null}
                              <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item add_hcp">
                                  <a
                                    onClick={(e) => addMoreHcp(e)}
                                    className="nav-link active btn-bordered"
                                    data-bs-toggle="tab"
                                    href="javascript:;"
                                  >
                                    {(localStorage.getItem("user_id") == userId || localStorage.getItem("user_id") == "sNl1hra39QmFk9HwvXETJA==")
                                      ? "Add User +"
                                      : "Add HCP +"}
                                  </a>
                                </li>

                              
                              </ul>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary save btn-filled"
              onClick={(e) => {
                saveClicked(e);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return state;
};

export default ViewTable;
